.modal {
    z-index: 2000 !important; /* Replace 2000 with your desired value */
  }
  
.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 1240px;
    max-height: 120vh;
}

.reportTopIcon{
    width: 32px;
     height: 32px;
     cursor: pointer;
}

.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    /* border-radius: 15px; */
    max-height: 120vh;
    overflow: hidden;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none;
}

.iconContainer {
    position: fixed;
    top: 13px;
    right: 33px;
    display: flex;
    z-index: 9999;
}

.save {
    width: 104px;
    height: 32px;
    background: #769AD5;
    border-radius: 30px;
    font: normal normal 600 12px/17px Open Sans;
    color: #E5E5E5;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    cursor: pointer;
}

.alias {
    font: normal normal 600 19px/13px Open Sans;
    color: #000000;
}

.headerTitle {
    font: normal normal bold 19px/13px Open Sans;
    color: #000000;
    
}

.reportNo {
    font: normal normal 600 19px/13px Open Sans;
    color: #000000;
}

.header {
    height: 37px;
    background: #EAEAEA; 
    font: normal normal 600 9px/13px Open Sans;  
    color: #000000;
    font-size: 10px;
}

.dayLabel {
    font: normal normal 600 10px/14px Open Sans;
    color: #050505;
}

.blackRow {
    background: #000000;
    height: 49px;
    font: normal normal bold 12px/17px Open Sans;
    color: #FFFFFF;
    text-transform: capitalize;
}

.textArea {
    min-height: 93px;
    background: #FFFFFF;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    font: normal normal normal 9px/13px Open Sans;
    color: #000000;
}

.modalBody {
    max-height: 90vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modalBody::-webkit-scrollbar {
    display: none;
}