.container {
    /* width: 100%; */
    height: 380px;
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A326;
    border-radius: 15px;
}

.legendContainer {
    width: 280px;
    height: 350px;
    background: #FAFAFA;
    border-radius: 15px;
    margin-top: 0px;
}

.legendDelay {
    font: normal normal 600 12px/17px Open Sans;
    color: #000000;
}

.legendTotalLabel {
    font: normal normal normal 12px/17px Open Sans;
    color: #000000;
}

.legendLabels {
    font: normal normal normal 11px/15px Open Sans;
    color: #000000;
}

.legendValues {
    font: normal normal 600 11px/15px Open Sans;
    color: #000000;
}

.DailySectionTitle {
    font: normal normal 600 12px/24px Open Sans;
    color: #000000;
}

.totalDelayLabel {
    font: normal normal normal 11px/24px Open Sans;
    color: #000000;
}

.totalDelayValue {
    font: normal normal 600 12px/24px Open Sans;
    color: #000000;
}

.byTypeLabel {
    font: normal normal normal 9px/24px Open Sans;
    color: #646464;
}

.types {
    font: normal normal normal 11px/15px Open Sans;
    color: #000000;
}

.typesValue {
    font: normal normal 600 11px/15px Open Sans;
    color: #000000;
}

.viewDetails {
    text-decoration: underline;
    font: normal normal 600 10px/22px Open Sans;
    color: #006BED;
    cursor: pointer;
}