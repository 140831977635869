.container {
  position: fixed;
  top: 8.45em;
  right: 2rem;
  z-index: 9999;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 660px;
}

.modal {
  width: 100%;
  height: calc(100vh - 11.3em);
  border-radius: 0px 10px 10px 0px;
  background-color: white;
  box-shadow: -1px 1px 6px #0000001a;
  border: 1px solid #e1e8f4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.top {
  /* border: 2px solid black; */
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.mid {
  flex: 1;
  margin-bottom: 1rem;
  overflow: auto;
  position: relative;
}

.subtitle {
  border: 1px solid #e1e8f4;
  background-color: #f0f4fa;
  padding: 6px 30px;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
}

.addBtn {
  font-size: 12px;
  color: white;
  padding: 0px 20px;
  border-radius: 15px;
  background-color: #186fd9;
  cursor: pointer;
}

.body {
  min-height: 100px;
}

.tableCell,
.tableHead {
  /* border: 1px solid black; */
  padding: 4px 8px;
}

.tableHead {
  color: #576b8d;
  font-size: 12px;
  font-weight: 600;
}

.tableCell {
  color: #1a1a1a;
  font-size: 14px;
}

.tableRow:hover {
  background-color: #e1e8f4;
}

.close {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  height: max-content;
}

.close:hover {
  background-color: #e2ecfb;
}

.close:focus {
  background-color: #b5c7e2;
}
