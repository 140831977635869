.arrow::before {
  content: "\25b2";
  font-family: "Font Awesome 6 Free";
  float: right;
  margin-left: 5px;
}

.collapsed .arrow::before {
  content: "\25bc";
}
.arrow2::before {
  content: "\25b2";
  font-family: "Font Awesome 6 Free";
  float: right;
  margin-left: 5px;
}

.collapsed .arrow2::before {
  content: "\25bc";
}
.arrow3::before {
  content: "\25b2";
  font-family: "Font Awesome 6 Free";
  float: right;
  margin-left: 5px;
}

.collapsed .arrow3::before {
  content: "\25bc";
}
.collapsed {
  background-color: #f0f0f0;
}
.map-drawer-container {
  box-shadow: 0px 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
  border-radius: 0.4rem;
  margin-top: 1.5rem;
  background: white;
  margin-left: 1.875rem;
  min-width: 27rem;
  max-height: 60rem;
  overflow-y: scroll;
  /* min-height: 13rem; */
}
.map-drawer-heading {
  margin-top: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 0.1rem solid rgb(236, 234, 234);
  text-transform: uppercase;
  opacity: 1;
  color: #000000;
}
.arrow-button {
  height: 1.2rem;
  width: 3rem;
}

.map-dropdowns {
  text-align: left;
  letter-spacing: 0px;
  text-transform: uppercase;
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 0.64rem;
}
.up-down-arrow-button {
  width: 1rem;
  height: 1rem;
}

.custom-scroller ::-webkit-scrollbar {
  width: 0px;
}
