.container {
    width: 698px;
    background: #F8FAFD;
    border: 1px solid #E1E8F4;
}

.clearFilters {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 30px;
    background: #FFFFFF;
    border: 1px solid #E1E8F4;
    border-radius: 15px;
    font: normal normal bold 12px/17px Open Sans;
    
}