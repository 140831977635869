.container {
    margin-right: -5px;
}

.card {
    border-bottom: 1px solid #E1E8F4;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.date {
    font-size: 12px;
    color: #6A7C9A;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 10px;
}

.changesCard {
    color: #1A1A1A;
    font-size: 12px;
    display: flex;
    gap: 10px;
}

.key {
    font-weight: bold;
}