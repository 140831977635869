.menu {
    display: flex;
    flex-direction: row;
    gap: 30px;
    font: normal normal 600 14px/19px Open Sans;
    color: #6A7C9A;
}

.menuItem.active {
    font: normal normal bold 14px/19px Open Sans;
    color: #186FD9;
    border-bottom: #186FD9 2px solid;
}