p { margin: 0; }

.container {
    width: 520px;
    height: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -1px 1px 6px #0000001A;
    border: 1px solid #E1E8F4;
    display: flex;
    flex-direction: column;
}

.header {
    padding: 30px 40px;
    border-bottom: 1px solid #E1E8F4;
}

.header > p {
    font-size: 16px;
    margin: 0;
    color: #1a1a1a;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 34px 40px;
    height: calc(100% - 8rem);
}

.load-tracker-label {
    color: #1A1A1A;
    font-size: 16px;
    font: normal normal 600 16px/22px Open Sans;
    margin-left: 4px;
}

.load-tracker-value {
    font: normal normal bold 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #1A1A1A;
    font-weight: 'bold';
}

.tracker-container {
    overflow-y: scroll;
    height: 100%;
}