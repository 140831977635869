.manage-user-content {
  height: calc(100% - 2rem);
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important;
}
.manage-user-content-header {
  padding-left: 0rem !important;
  margin-bottom: 1.25rem !important;
}

.manage-user-filter-modal {
  background: #ffffff !important;
  box-shadow: -0.0625rem 0.0625rem 0.375rem #0000001a !important;
  border: 0.0625rem solid #e1e8f4 !important;
  position: fixed !important;
  bottom: 0rem !important;
  width: 50rem !important;
  height: auto !important;
  z-index: 999999 !important;
  @media (max-width: 1499px) {
    top: 11rem;
  }
  @media (max-width: 1023px) {
    top: 11.4rem;
  }
  @media (max-width: 767px) {
    top: 19rem;
  }
  @media (min-width: 1500px) {
    top: 10.6rem;
  }
  @media (min-width: 1800px) {
    top: 10.75rem;
  }
}

.table_manage_user {
  width: 100% !important;
  min-height: 70vh !important;
  position: relative !important;
  border-radius: 0.5rem !important;
  background: var(--e-global-color-white) !important;
  overflow: hidden !important;
}

.user_popup {
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;
  background: transparent;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.manage-user-action-btn {
  border-radius: 4px !important;
  height: 3rem !important;
  width: 8.5rem !important;
  color: #ffffff !important;
  font: normal normal bold 12px/17px Open Sans !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer !important;
}
