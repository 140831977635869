.datePickerContainer {
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  border-radius: 15px;
  width: 160px;
  height: 30px;
  user-select: none;
  position: relative;

  font-size: 16px;
}

.datePickerContainer:focus-within {
  border: 1px solid #0d7ffb;
}
.calendarIcon {
  color: #1a73e8;
  font-size: 12px;
  margin: 0px 8px;
}

.customInput {
  border: none;
  outline: none;
  color: #6b7b8e;
  font: normal normal 600 14px/14px Open Sans;
  /* color: #000000; */
  height: 100%;
  border-radius: 15px;
  width: 100%;
  cursor: pointer;
}
.datePickerContainer:hover,
.datePickerContainer:hover .customInput,
.datePickerContainer:hover .datePickerContainer,
.datePickerContainer:hover .calendarIcon,
.datePickerContainer:hover .dropdownIcon {
  background-color: #fafafa !important;
}

.customInput::placeholder {
  font: normal normal 600 13px/14px Open Sans;
  letter-spacing: 0px;
  color: #000000;
}

.dropdownIcon {
  padding-right: 5px;
  position: absolute;
  right: 12px;
  font-size: 16px;
  cursor: pointer;
  color: #777777;
}

/* input:disabled {
  background-color: None !important;
} */
