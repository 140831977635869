.trigger {
    background-color: #E2ECFC;
    color: #1A1A1A;
    font-size: 14px;
    font-weight: 600;
    border-radius: 40px;
    padding: 4px 10px;
    justify-content: space-between;
    cursor: pointer;
}

.showContent {
    background-color: #07273F;
    color: white;
}