/* DayNightToggleButton.css */

/* Styles for the button container */
.button-container {
    width: 6.5rem;
    box-shadow: 0px 0px 3px rgba(171, 171, 171, 0.7);
    border-radius: 0.938rem;
}

/* Common styles for both day and night buttons */
.button {
    border-radius: 0.938rem;
    cursor: pointer;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    height: 1.75rem;
    display: flex;
    align-items: center;
}

/* Styles for the "Day" button */
.day-button {
    width: 3.25rem;
    background-color: white;
    color: #6A7C9A;
}

.day-button.active {
    background-color: rgb(20, 36, 62);
    color: white;
}

/* Styles for the "Night" button */
.night-button {
    width: 3.25rem;
    background-color: white;
    color: #6A7C9A;
}

.night-button.active {
    background-color: rgb(20, 36, 62);
    color: white;
}

.buttonVD{
    min-width: 7rem;
        border-radius: 0.813rem;
        height: 1.7rem;
        font-style: italic;
        background-color: white;
        color: #576B8D;
        border : 1px solid #E1E8F4;
}

.buttonVD.active{
    background-color: #14233C;
    color: white;
}