.datePicker {
  position: relative;
  display: flex;
  gap: 10px;
}

.dateContainer {
  position: absolute;
  z-index: 1;
  top: 2.5rem;
  background-color: white;
  border-radius: 0.9375rem;
  box-shadow: 0rem 0.1875rem 0.375rem #00000029;
  border: 0.0625rem solid #ebebeb;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthPicker {
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  max-height: 18.75rem;
  overflow: auto;
}

.monthOptions {
  padding: 0.5rem 1.25rem;
  cursor: pointer;
}

.monthOptions:hover {
  background-color: #ebebeb;
}

.fromTo {
  display: flex;
  justify-content: space-between;
  gap: 1.25rem;
  padding: 0rem 1.25rem;
  margin-top: 0.8125rem;
}

.from {
  display: flex;
  align-items: center;
  gap: 11px;
  flex-grow: 1;
  /* color: #707070;
    font-size: 0.75rem; */
  font: normal normal normal 12px/17px Open Sans;
  color: #707070;
  padding: 0.1875rem 0.3125rem;
}

.dateVal {
  /* border: 0.0625rem solid #EBEBEB;
    border-radius: 0.9375rem;
    background-color: #FFFFFF; */
  /* height: 1.5rem;
    width: 7.5rem; */
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ebebeb;
  border-radius: 15px;
  width: 120px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: normal normal 600 11px/14px Open Sans;
  letter-spacing: 0px;
  color: #2c2c2c;
}

.btnContainer {
  display: flex;
  gap: 4.6875rem;
  justify-content: space-between;
  border-top: 0.0625rem solid #f4f4f4;
  margin-bottom: 1rem;
  padding-top: 0.9375rem;
}

.btn {
  background-color: white;
  border: 0.0625rem solid #2b2d2f;
  border-radius: 0.9375rem;
  width: 5rem;
  height: 1.875rem;
  color: #2b2d2f;
  font-size: 11px;
  font-weight: 600;
  cursor: pointer;
}

.btnSm {
  height: 1.5625rem;
}

.fill {
  border: none;
  color: #ffffff;
  /* background: #1264DB; */
}

.disabledBtn {
  color: #9fa2a7;
  background: #eaeaea;
  border: none;
  cursor: not-allowed !important;
}

.dateButton {
  display: flex;
  border-radius: 0.1875rem;
  font-size: 0.75rem;
  /* width: 190px; */
  height: 30px;
}

.calendarIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 35px;
  /* top: 3px; */
}

.modeToggle {
  display: flex;
  /* padding: 0.25rem; */
}

.toggle {
  padding: 0.125rem 0.25rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle img {
  height: 10px;
  width: auto;
}

.dateDisplay {
  border-radius: 15px;
  background-color: #ffffff;
  cursor: pointer;
  /* min-width: 9.375rem; */
  /* height: 1.875rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-weight: 600; */
  font: normal normal 600 11px/14px Open Sans;
  color: #000000;
  width: 185px;
  height: 30px;
}
.dateDisplay span {
  margin-left: 15px;
}

.dateDisplay:hover {
  background-color: #fafafa;
}

.dateDisplay.disabled {
  background-color: #f2f2f2;
}

.dateDisplay.disabled span {
  opacity: 0.5;
}

.customNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15.625rem;
  margin-top: 1.3125rem;
  color: #1264db;
  font-size: 0.875rem;
  font-weight: 600;
}

.wholeMonthLabel {
  color: #1264db;
  font-size: 11px;
  display: flex;
  padding: 0rem;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 600;
}

/* Style for the start of the range */
:global(.rdp-day.rdp-range_start) {
  /* background-color: red !important; */
  color: #fff !important;
  border-radius: 50% !important;
}

/* Style for the end of the range */
:global(.rdp-day.rdp-range_end) {
  color: #fff !important;
  border-radius: 50% !important;
}

:global(.rdp-root) {
  --rdp-accent-color: #1264db !important;
  --rdp-day_button-height: 30px !important;
  --rdp-day_button-width: 30px !important;
  --rdp-range_start-background: transparent !important;
  --rdp-range_end-background: transparent !important;
  --rdp-weekday-opacity: 1 !important;
}
