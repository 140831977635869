.weatherReport {
  width: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  border: none;
  opacity: 1;
}

.weatherDetail {
  background: #3d93ff;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(0px);
  width: 35%;
}

.cloudy {
  font: normal normal normal 16px/22px Open Sans;
  color: #ffffff;
}

.temp {
  font: normal normal normal 48px/58px "Inter", sans-serif;
  color: #ffffff;
}

.tempSM {
  font: normal normal normal 14px/22px "Inter", sans-serif;
  color: #ffffff;
}

.commonWeather {
  font: normal normal normal 14px/19px Open Sans;
  color: #ffffff;
}

.commonVal {
  font: normal normal 600 14px/19px Open Sans;
  color: #ffffff;
}

.weatherReportClose {
  font-size: x-large;
  cursor: pointer;
  float: right;
  transform: matrix(0, 1, -1, 0, 0, 0);
  opacity: 0.8;
  color: #ffffff;
}

.weatherToday {
  background: #ffffff2d;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
  opacity: 1;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  margin-top: 25px;
}

.DayForCast {
  background: #ffffff3d;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
  opacity: 1;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
  margin-top: 25px;
}

.weatherCloudFont {
  font: normal normal normal 12px/14px "Inter", sans-serif;
  letter-spacing: 0px;
  user-select: none;
  color: #ffffff;
}

.weatherCloudTemp {
  font: normal normal 600 14px/16px "Inter", sans-serif;
  user-select: none;
  color: #ffffff;
  padding-bottom: 35px;
}

/* .weatherTodayCloud {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    max-width: 900px;
    height: 110px;
    box-sizing: border-box;
    scroll-behavior: smooth;
    padding-top: 40px;

}

.weatherTodayCloud::-webkit-scrollbar {
    height: 6px;
    border-radius: 15px;

}

.weatherTodayCloud::-webkit-scrollbar-thumb {
    background-color: #D3DFED;
    border-radius: 15px;
}

.weatherTodayCloud::-webkit-scrollbar-track {
    background-color: #63A3EF;
} */

.weatherTodayCloud {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  max-width: 900px; /* Adjust container width */
  height: 110px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  padding-top: 40px;
  position: relative;
}

/* Scrollbar Styling */
.weatherTodayCloud::-webkit-scrollbar {
  height: 4px; /* Controls the height of the scrollbar */
  border-radius: 8px;
}

.weatherTodayCloud::-webkit-scrollbar-thumb {
  background-color: #d3dfed; /* Thumb color */
  border-radius: 8px;
  min-width: 30px; /* Use 'min-width' to ensure the thumb doesn't shrink */
  width: 30px; /* Standard thumb width for consistent size */
  transition: background-color 0.3s ease;
}

.weatherTodayCloud::-webkit-scrollbar-track {
  background-color: #63a3ef; /* Track color */
  border-radius: 8px;
  margin: 0 15px; /* Optional: Adjust margin to control scroll length */
}
