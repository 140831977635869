.radioLabel {
    font: normal normal 600 12px/14px Open Sans;
    color: #6A7C9A;
    opacity: 1;
}

.optionLabel {
    font: normal normal 600 12px/17px Open Sans;
    color: #6A7C9A;
}



.radioInput {
    /* appearance: none; */
    background: #F8FAFD;
    border: 1px solid #E1E8F4;
    opacity: 1;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }


