.filterbtn {
  border: 1px solid #e1e8f4;
  border-radius: 2rem;
  color: #1a1a1a;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 8px;
  gap: 2px;
  cursor: pointer;
  margin-left: 10px;
}
/* 
.filterbtn:hover {
    background-color: #E2ECFC;
} */
