body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify .Toastify__toast-container {
  z-index: 99999999;
}
.popup-field {
  font: normal normal normal 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #707070;
}
.popup-field-value {
  font: normal normal 600 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #000000;
}
.flyout {
  width: 7px;
  height: 17px;
  opacity: 1;
  margin-left: 0.93rem;
  margin-top: 0.76rem;
  margin-right: 0.34375rem;
}
.flyout {
  width: 13.125rem;
  height: 17.5rem;
  background: #ffffff;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  margin-left: 3rem;
  margin-top: 3rem;
  position: absolute;
  border-radius: 1rem;
  z-index: 1;
}
.mm-body {
  /* font-size: 1.4rem; */
  line-height: 2.5rem;
  font-weight: 400;
  overflow-x: hidden;
  color: var(--e-global-color-dark-blue);
  scroll-behavior: smooth;
  font-size: 75%;
  height: 100%;
  position: absolute;
  z-index: 1;
  padding: 1rem 3rem;
}
.mm-top_heading h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--e-global-before-rightbar-text-color);
}
