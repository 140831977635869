.container {
    min-height: 450px;
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A326;
    border-radius: 15px;
    gap: 1rem;
}

.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table th:first-child{
    border-radius:40px 0 0 40px;
  }
  
.table th:last-child{
    border-radius:0 40px 40px 0;
  }

.table-header {
    z-index: 1;
    position: sticky;
    background-color: #FAFAFA;
    border-radius: 160px;
}

.table-header th {
    color: #707070;
    font-family: Open Sans;
    font-weight: 600;
    font-size: 12px;
    padding: 8px 28px;
    white-space: nowrap;
}

.table-row td {
    color: #28354D;
    font-weight: 600;
    font: 'Open Sans', sans-serif;
    font-size: 12px;
    padding: 8px 28px;
    border-bottom: 1px solid #FAFAFA;
}

.table-row:hover {
    background-color: white;
    cursor: auto;
}

.no-data {
    color: #ADADAD;
    font-family: 'Open Sans';
    font-size: 18px;
}