.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 900px;
    max-height: 110vh;
}

.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    border-radius: 15px;
    max-height: 110vh;
    overflow: hidden;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none;
}

.titleSummary {
    font: normal normal bold 20px/35px Open Sans;
    color: #000000;
}

.titleShift {
    font: normal normal 600 20px/35px Open Sans;
    color: #000000;
}

.titleDate {
    font: normal normal normal 20px/35px Open Sans;
    color: #000000;
}

.reportStatus {

    border-radius: 10px;
}

.reportLabel {
    font: normal normal bold 18px/17px Open Sans;
    color: #000000;
}

.totalLabel {
    font: normal normal bold 14px/15px Open Sans;
    color: #000000;
}

.targetLabel {
    font: normal normal 600 14px/15px Open Sans;
    color: #000000;
}

.remainingLabel {
    font: normal normal 600 14px/15px Open Sans;
    color: #000000;
}

/* .modalBody {
    max-height: 75vh;
    overflow-y: auto;
} */
.modalBody {
    max-height: 75vh;
    overflow-y: scroll;
    /* Enable scrolling */
    scrollbar-width: none;
    /* Hide scrollbar in Firefox */
    -ms-overflow-style: none;
    /* Hide scrollbar in Internet Explorer */
}

.modalBody::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar in Chrome, Safari, and Edge */
}

.singleHeader {
    font: normal normal 600 12px/17px Open Sans;
    color: #000000;
    text-transform: capitalize;
    background: #FAFAFA;
    text-align: center;
    border-bottom: 1px solid #DEDEDE;
    border-top: 1px solid #DEDEDE;
}

.operatorData {
    font: normal normal 600 10px/14px Open Sans;
    color: #576B8D;
    border-bottom: 1px solid #E1E8F4;
}

.operatorWidth20 {
    width: 20%;
    text-align: left;
}

.operatorWidth40 {
    width: 40%;
    text-align: left
}

.viewOperator {
    width: 20%;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 11px/11px Open Sans;
    color: #186FD9;
    cursor: pointer;
}

.supervisor {
    font: normal normal normal 10px/14px Open Sans;
    color: #000000;
}

.comments {
    font: normal normal bold 10px/14px Open Sans;
    color: #000000;
    width: 50%;
}

.signature {
    font: normal normal bold 10px/14px Open Sans;
    color: #000000;
}

.addSignatureBtn {
    background: #FFFFFF;
    border: 1px solid #006BED;
    text-align: center;
    font: normal normal 600 12px/13px 'Open Sans', sans-serif;
    color: #386DFF;
    border-radius: 20px;
    width: 63px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.noSignature {
    border-bottom: 1px solid #EBEBEB;
    border-top: 1px solid #EBEBEB;
    font: normal normal normal 10px/14px 'Open Sans', sans-serif;
    color: #707070;
    width: 30%;
    margin-bottom: 45px;
    margin-right: 20px;
}

.saveBtn {
    background: #3880F5;
    border-radius: 30px;
   
    font: normal normal 600 12px/17px 'Open Sans', sans-serif;
    color: #FFFFFF;
    width: 203px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.dualHeaderLeft {
    border-bottom: 1px solid #E1E8F4;
    border-right: 1px solid #E1E8F4;
    width: 50%;
    height: 45px;
}

.dualType {
    font: normal normal 600 11px/11px Open Sans;
    color: #050505;
    width: 15%;
}

.dualData {
    font: normal normal normal 11px/11px Open Sans;
    color: #28354D;
    width: 70%;
}

.dualTime {
    font: normal normal normal 11px/11px Open Sans;
    color: #28354D;
    width: 15%;
}

.dualHeaderRight {
    border-bottom: 1px solid #E1E8F4;
    width: 50%;
    height: 45px;
}

.assetHeader {
    width: 20%;
    font: normal normal 600 10px/14px Open Sans;
    color: #576B8D;
}

.assetData {
    width: 20%;
    font: normal normal 600 11px/11px Open Sans;
    color: #050505;
}

.totals {
    background: #14233C;
    font: normal normal 600 20px/27px Open Sans;
    color: #FFFFFF;
    text-transform: capitalize;
    text-align: center;
}

.totalLoads {
    font: normal normal bold 22px/18px Open Sans;
    color: #050505;
}

.iconContainer {
    position: fixed;
    top: 7px;
    right: 29px;
    display: flex;
    z-index: 1051;
}


.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    padding: 5px;
    border-radius: 50%;
    transition: background 0.3s ease, color 0.3s ease;
}

.ripple {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    opacity: 0.25;
    background-color: black;
    z-index: 1000;
}

.reportTopIcon{
    width: 32px;
     height: 32px 
}
.disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}

