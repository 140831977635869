.dateTimeSelect input {
    color: #6b7b8e !important;
  }
  
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: #8190ad;
    background-color: #e1e4ea;
  }
  
  
  