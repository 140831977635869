.load-table {
  color: black;
  font-size: 1rem;
  width: 100%;
  background-color: white;
}

.load-table thead {
  color: darkgray;
  border: 1px solid #e1e8f4;
  font: normal normal 600 12px/17px;
  font-size: 1rem;
  color: #576b8d;
}

.load-table td {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: white;
}

.load-table th:first-child {
  padding-left: 4rem;
  background-color: white;
}

.load-table td:last-child {
  padding-right: 2rem;
  background-color: white;
}

.load-table tbody tr {
  border-bottom: lightgray 1px solid;
  background-color: white;
}

.footer {
  position: sticky;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #e1e8f4;
  background-color: white;
  padding: 18px;
  display: flex;
}

.footer > p {
  font-size: 12px;
  color: #6a7c9a;
  font: normal normal normal 12px/18px Open Sans;
}
