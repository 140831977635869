

.title {
    font: normal normal 600 14px/19px Open Sans;
    color: #000000;
    text-transform: uppercase;
}

.header {
    font: normal normal 600 12px/17px Open Sans;
    color: #000000;
}

.target {
    background: #FAFAFA;
    border-radius: 15px;
    height: 48px;
    gap:2px
}

.actualLabel {
    font: normal normal normal 8px/11px Open Sans;
    color: #6F7070;
}

.actualValue {
    font: normal normal 600 12px/17px Open Sans;
    color: #000000;
}

.mtdActualLabel{
    font: normal normal normal 11px/15px Open Sans;
    color: #000000;
}

.mtdActualValue{
    font: normal normal 600 11px/15px Open Sans;
    color: #000000;
}

