.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 465px;
    max-height: 212px;
}

.modal{
    z-index: 9999;
}

.iconContainer {
    position: fixed;
    top: 13px;
    right: 33px;
    display: flex;
    z-index: 9999;
}


.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    border-radius: 15px;
    max-height: 212px;
    overflow: hidden;
    width: 465px;
    height: 212px;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none
}

.closeIcon {
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px,
}

.title {
    font: normal normal 600 14px/14px Open Sans;
    color: #000000;
}

.border {
    border-bottom: 1px solid #F1F1F1;
    opacity: 0.8;
}

.continue {
    background: #3880F5;
    box-shadow: 0px 0px 35px #4F74D940;
    border-radius: 15px;
    width: 101px;
    height: 30px;
    font: normal normal 600 11px/15px Open Sans;
    color: #FFFFFF;
    text-align: center;
    margin-left: 135px;
}