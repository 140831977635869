.endShiftModal {
  width: 450px;
  background: #ffffff;
  box-shadow: 0px 0px 45px #a3a3a329;
  border-radius: 15px;
}
.title {
  font: normal normal 600 14px/14px Open Sans;
  color: "#000000";
}
.header {
  padding: 30px 0 17px 0;
  border-bottom: 1px solid #f2f2f2;
}

.exHeader {
  font: normal normal normal 11px/37px Open Sans;
  color: #576b8d;
}

.selectAll {
  font: normal normal normal 12px/37px Open Sans;
  color: #585858;
}
.data {
  font: normal normal normal 12px/37px Open Sans;
  color: #1a1a1a;
}

.endShiftBtn {
  width: 101px;
  height: 35px;
  /* UI Properties */
  background: #3880f5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 35px #4f74d940;
  border-radius: 20px;
  font: normal normal 600 11px/15px Open Sans;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  display: flex;
  opacity: 0.9;
  cursor: pointer;
}

.endShiftBtn_disabled {
  background-color: #e1e4eb;
  color: #8190ad;
  cursor: not-allowed;
}

.userCheckbox {
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background: #f8fafd;
  border: 1px solid #e1e8f4;
  border-radius: 2px;
  position: relative;
  opacity: 1;
  cursor: pointer;
}

.userCheckbox:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0rem;
  left: 0.45rem;
  width: 0.375rem;
  height: 1rem;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(45deg);
}

.userCheckbox:checked {
  background: #186fd9;
  outline: none;
  border: 1px solid #e1e8f4;
}

.userCheckbox:checked:after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.closeIcon {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
 align-items: center;
  justify-content: center;
  padding: 1px,
}