.download-modal-container {
  .bold-md {
    font-weight: 600;
  }
  width: 450px;
  display: flex;
  flex-direction: column;
  position: relative;
  .download-modal-header {
    font-size: 16px;
    font-weight: bold;
    padding-left: 1.5rem;
    height: 8%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #00000029;
  }
  .download-modal-body {
    width: 100%;
    height: 92%;
    padding: 1.5rem;
    font-size: 12px;
    input {
      width: 100%;
      border: 2px solid #e1e8f4;
    }
    .date-picker-container {
      display: flex;
      height: 48px;
      align-items: center;
      background-color: #fff;
      border: 2px solid #e1e8f4;
      border-radius: 5px;
      padding-right: 7px;
      input[type="text"] {
        border: none;
        outline: none;
      }
      img {
        height: 25px;
        width: 25px;
      }
    }
    .date-picker-input::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      display: block;
      background: transparent;
      content: url(../../calendar.png) !important;
      width: 25px;
      height: 25px;
      border-width: thin;
    }
    .date-btns {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #e1e8f4;
        width: 88px;
        height: 28px;
        border-radius: 18px;
        font-size: 10px;
        color: #576b8d;
        cursor: pointer;
      }
    }
    .btn-container {
      display: flex;
      justify-content: flex-end;
    }
    .select-dropdown {
      .value-box {
        height: 45px;
        border: 2px solid #e1e8f4;
        display: flex;
        align-items: center;
        padding: 5px 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        img {
          height: 30px;
          cursor: pointer;
        }
      }
      .option-container {
        box-shadow: 1px 6px 10px #00000029;
        background-color: white;
        z-index: 1;
        position: absolute;
        width: 400px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        .search input {
          width: 400px;
          border: none;
          outline: none;
          border-bottom: 2px solid #00000029;
          border-radius: 0px;
        }
        .option {
          display: flex;
          justify-content: flex-start;
          padding: 10px 0px 10px 0px;
          cursor: pointer;
          input {
            width: 10%;
          }
        }
        .done {
          display: flex;
          justify-content: flex-end;
          padding-right: 10px;
        }
      }
      .hide {
        display: none;
      }
    }
  }
}
