.menu {
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  gap: 35px !important;
  font: normal normal 600 14px/19px Open Sans !important;
  color: #6a7c9a !important;
}

.menuItem.active {
  font: normal normal bold 14px/19px Open Sans !important;
  color: #186fd9 !important;
  border-bottom: #186fd9 2px solid !important;
}

.unreadCount {
  width: 21px;
  height: 21px;
  background: #edf4ff;
  font: normal normal 600 14px/22px Open Sans;
  color: #186fd9;
}
