.container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(8, 22, 41, 0.85);
}
.modal {
  min-height: 100px;
  width: 450px;
  box-shadow: 1px 6px 10px #00000029;
  border: 1px solid #e1e8f4;
  border-radius: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
}
.top {
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  img {
    cursor: pointer;
    height: 24px;
    width: 24px;
    padding: 2px;
  }
}
.top > div {
  display: flex;
  justify-content: space-between;
}
.mid {
  display: flex;
  flex-direction: column;
}
.trucks,
.excavators {
  min-height: 100px;
}
.header {
  background-color: #f0f4fa;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: bold;
  width: 100%;
  padding: 5px 30px;
  span {
    color: #6a7c9a;
    font-weight: bold;
    font-style: italic;
  }
}
.options {
  max-height: 200px;
  overflow: auto;
  padding: 10px 0;
  position: relative;
}
.check {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 2px 30px;
  font-weight: 700;
  font-size: 15px;
}
.check:hover {
  background-color: #e2ecfc;
}
.bottom {
  border-top: 1px solid #e1e8f4;
  box-shadow: 0px -2px 4px #0000000a;
  padding: 10px 30px;
  display: flex;
  justify-content: flex-end;
}
.btn {
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 3px 16px;
  border-radius: 20px;
  background-color: #186fd9;
  cursor: pointer;
}
