.ex-card {
  width: calc(25% - 18px);
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  border: none;
  opacity: 1;
  margin-bottom: 30px;
  padding-top: 27px;
  align-self: flex-start;
}
.excavator-card-header {
  border-bottom: 1px solid #f2f2f2;
  padding: 0 26px;
}
.trucksSection {
  display: flex;
  flex-direction: column;
}

.truckId {
  padding-right: 40px;
}
.excDetail {
  text-align: left;
  font: normal normal 600 14px/19px Open Sans;
}
.excPadding {
  padding: 10px;
}

.excTruckDetails {
  display: flex;
  align-items: center;
  /* gap: 10px; */
}

.operatorName {
  text-align: left;
  font: normal normal normal 11px/15px Open Sans;

  color: #6f7070;
  opacity: 1;
}
.average {
  text-align: right;
  font: normal normal normal 11px/15px Open Sans;
  color: #6f7070;
}
.averageData {
  color: "#000000";
  text-align: left;
  font: normal normal normal 11px/15px Open Sans;
}

.truckCount {
  padding: 0 26px;
  text-align: left;
  font: normal normal normal 10px/14px Open Sans;
  color: #6f7070;
  opacity: 1;
}
.footerTruck {
  background: #f4f6fd 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 15px 15px;
  opacity: 1;
}
