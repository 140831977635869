body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-container {
  z-index: 99999999 !important;;
}

 select {
  background: url("data:image/svg+xml,<svg width='10px' height='10px' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h48v48H0z' fill='none' /><g id='Shopicon'><g><polygon points='24,29.171 9.414,14.585 6.586,17.413 24,34.827 41.414,17.413 38.586,14.585 		' /></g></g></svg>") no-repeat;
    background-position: calc(100% - 1.2rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
}


 .date-picker-calander-header select {
   background: url("data:image/svg+xml,<svg width='10px' height='10px' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'><path d='M0 0h48v48H0z' fill='none' /><g id='Shopicon'><g><polygon points='24,29.171 9.414,14.585 6.586,17.413 24,34.827 41.414,17.413 38.586,14.585 		' /></g></g></svg>") no-repeat;
   background-position: calc(100% - 0.6rem) center !important;
   -moz-appearance: none !important;
   -webkit-appearance: none !important;
   appearance: none !important;
   padding-right: 2rem !important;
 }

 .equipment-productive{
  padding: 1rem;
 }