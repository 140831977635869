.user-phone-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    height:3.5rem !important;
  }
  
.user-phone-input {
height: 40px;
  }
  