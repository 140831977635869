.mainContainer {
  position: relative;
  user-select: none;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  aspect-ratio: 1/1;
  border-radius: 100%;
}

.iconContainer:hover {
  background-color: #b5c7e2;
}

.iconContainer:active {
  background-color: #b5c7e2;
}

.optionsContainer {
  position: absolute;
  border: 1px solid #e1e8f4;
  border-radius: 4px;
  box-shadow: 1px 3px 4px #00000029;
  z-index: 100;
  right: 23.5px;
  top: 4px;
  padding: 4px 0px;
   width: 160px;
}

.option {
  min-width: 140px;
  text-align: start;
  color: #1a1a1a;
  font-size: 12px;
  user-select: none;
  padding: 4px 20px;
  font-weight: normal;
  cursor: pointer;
}

.option:hover {
  background-color: #e1e8f4;
  width: 100%;
}

.disabled {
  cursor: not-allowed;
  color: #8190ad;
  background-color: #e1e4ea;
}

.disabled:hover {
  background-color: #e1e4ea;
  width: 100%;
}
