.dashboard__header__bar {
  width: 18px;
  height: 8px;
  border-radius: 7px;
  opacity: 1;
  margin-right: 7px;
}
.dashboard__header__bar__label {
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;
  max-width: fit-content;
}
.dashboard__top__slider__show {
  height: 255px;
  /* overflow:'' */
  padding: 25px 3000px;
}
.dashboard__top__slider__hide {
  /* visibility: none; */
  opacity: 0;
  height: 0;
  padding: 0;
}

.custom-toggle {
  background-color: #dbe5f3;
  border: none;
  color: #000;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 4px 1rem;
}

.custom-toggle:active,
.custom-toggle:focus,
.custom-toggle:hover {
  background-color: #00214d;
  color: #fff;
}

.custom-toggle::after {
  display: none;
}

.custom-toggle:active {
  outline: 1px solid #e1e8f4 !important;
}

.dropdown-menu-m.show {
  position: absolute !important;
  height: max-content;
  width: max-content;
  top: -2.7rem !important;
  right: 6.5rem !important;
  box-shadow: -1px 1px 6px #0000001a;
}

.custom-menu {
  position: absolute;
  left: 50% !important;
  top: 125% !important;
  transform: translateX(-50%) !important;
}
.custom-menu2 {
  position: absolute;
  left: -1rem !important;
  top: 125% !important;
  width: max-content;
  box-shadow: -1px 1px 6px #0000001a;
}

.date-picker {
  border-radius: 40px !important;
  height: 2.5rem;
  border: 1px solid #dde5f3;
}
