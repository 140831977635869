.shift-label {
    font: normal normal 600 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #1A1A1A;
}

.shift-ongoing-label {
    font: normal normal normal 16px/22px Open Sans !important;
}

.shift-total-btn {
    width: 96px;
    height: 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #006BED;
    border-radius: 20px;
    text-align: center;
    font: normal normal 600 12px/22px Open Sans;
    letter-spacing: 0px;
    color: #386DFF;
    opacity: 1;
}

.collapse-content {
    transition: height 0.3s ease;
    overflow: hidden;
}

.weatherBtn {
    box-shadow: None !important;
}


