.shiftHistoryModal {
  background-color: white;
  box-shadow: lightgray 0px 0px 10px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 512px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.shiftHistoryMenu {
  color: gray;
  display: flex;
  font-size: 1.2rem;
  flex-direction: row;
  font-weight: 600;
  gap: 30px;
}

.shiftHistoryItem.active {
  color: #2b7bdc;
  border-bottom: #2b7bdc 2px solid;
}

.headerLabel {
  font: normal normal normal 13px/18px Open Sans;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;
  max-width: fit-content;
}
