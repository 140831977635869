
  .toggleArrowButton span {
    margin-right: 8px;
    font-size: 14px;
    color: #333;
  }
  
  .toggleArrowOptionsContainer {
    position: absolute;
    top: 100%;
    left:0;
    z-index: 99999999 !important;
    min-width: 170px;
    margin-top: 2px;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  }
  
  .toggleArrowOption {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 8px 8px;
    font: normal normal normal 12px/18px Open Sans;
    color: #333;
    cursor: pointer;
    white-space: nowrap; 
  }
  
  .toggleArrowOption:hover {
    background-color: #E1E8F4;
  }
  
  .toggleArrowOptionDisabled {
    color: #adb5bd;
    cursor: not-allowed;
  }
  
  .optionArrowIcon {
    display: inline-flex;
    align-items: center;
    font: normal normal 200 20px Open Sans;
    flex-shrink: 0;
  }
  .optionIcon {
    display: inline-flex;
    align-items: center;
    font: normal normal 600 10px/8px Open Sans;
    flex-shrink: 0;
  }
  .optionLabel {
    font: normal normal normal 12px/18px Open Sans;
    margin-left: 8px;
  }
  
  .downArrowSvg {
    position: relative;
    top: -2px; 
    margin-left: 5px;
  }
  
  @media (max-width: 1770px) {


    .toggleArrowOption {
      white-space: normal;
      
    }

    .optionLabel {
      margin-top: 4px;
      margin-left: 8px;
    }
  }