.container {
  max-width: 800px;
}

.collapsibleContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.collapsible {
  background: transparent;
  border-radius: 20px;
}

.collapsibleHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #1a1a1a;
  cursor: pointer;
  background-color: #eff3fa;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.collapsibleHeader:hover {
  background-color: #eaeaea;
}

.collapsibleContent {
  padding: 15px;
  background: #ffffff;
  border-top: none;
  border-radius: 0 0 8px 8px;
  font-size: 14px;
  line-height: 1.5;
}

.label {
  font: normal normal normal 14px/17px Open Sans;
  color: #4b515a;
  width: 40%;
}

.value {
  font: normal normal 600 14px/19px Open Sans;
  color: #1a1a1a;
  width: 60%;
}

.categoryLabel {
  font: normal normal bold 12px/17px Open Sans;
  color: #1a1a1a;
}
