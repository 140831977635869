.viewDetailLabel {
    font: normal normal normal 14px/17px Open Sans;
    color: #4B515A;
    width: 30%;
}

.viewDetailValue {
    font: normal normal 600 14px/19px Open Sans;
    color: #1A1A1A;
    width: 70%;
}

.viewDetailWidth{
    width: 35%;
}