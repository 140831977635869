.add-user-button a {
    border: none !important;
    text-align: center !important;
    text-decoration: none !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 0.3125rem !important; 
    width: 7.25rem !important;
    height: 2.5rem !important; 
    cursor: pointer !important;
    font: normal normal bold 1rem/1.0625rem Open Sans !important;
    color: var(--e-global-color-white) !important;
    background: var(--e-global-color-blue-button-color) !important;
}

.user-modal-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.add-user-modal {
background: #FFFFFF 0% 0% no-repeat padding-box;
box-shadow: 0rem 0.625rem 0.875rem #0000001A;
border: 0.0625rem solid #E1E8F4;
width: 50rem;
bottom: 0rem;
position:fixed;
@media (max-width: 1499px) {
  top: 11rem;
}
@media (max-width: 1023px) {
  top: 11.4rem;
}
@media (max-width: 767px) {
  top: 19rem;
}
@media (min-width: 1500px) {
  top: 10.6rem;
}
@media (min-width: 1800px) {
  top: 10.75rem;
}

/* @media (max-width: 93.75rem) {
  top: 10.844rem;
} */
}

.add_new_user_box_top_right .discard {
  border-color: #b3c8ed !important;
  color: var(--e-global-color-blue-button-color) !important;
  background: var(--e-global-color-white) !important;
  margin-right: 0.5rem !important;
  border-radius: 4px !important;
  padding: 0.8rem 2rem !important;
}

.add_new_user_box_top_right .save {
  border-color: var(--e-global-color-blue-button-color) !important;
  color: var(--e-global-color-white) !important;
  background: var(--e-global-color-blue-button-color) !important;
}


.manage-user-filter-btn{
    border: 1px solid #E1E8F4;
    border-radius: 15px;
    height: 2.7rem;
    width: 6.5rem;
    color: #1A1A1A;
    font: normal normal 600 1.2rem Open Sans;
    display: flex;
    align-items: center;
    padding: 0rem 0.5rem;
    gap: 0.125rem;
    cursor: pointer;
    margin-left: 0.8rem;
}
.phone-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.phone-input-container input[type="tel"] {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #E1E8F4;
  border-radius: 4px;
}
.user-phone-conatiner{
width: 400px;
}

.my_account_imput_wrap {
font:normal normal normal 14px/22px Open Sans !important;
color: #092344 !important;
padding-bottom: 0px !important;
}

.non-empty-label {
  position: absolute !important;
  top: 0.1rem !important;
  left: 1.5rem !important;
  color: #677789 !important;
  font: normal normal normal 12px/22px Open Sans !important;
  pointer-events: none !important;
}

.empty-label {
  position: absolute !important;
  top: 1.4rem !important;
  left: 2rem !important;
  color: #677789 !important;
  font: normal normal normal 14px/22px Open Sans !important;
  pointer-events: none !important;
}
.empty-passwords-label {
  position: absolute !important;
  top: 1.4rem !important;
  left: 2rem !important;
  color: #092344 !important;
  font: normal normal normal 14px/22px Open Sans !important;
  pointer-events: none !important;
}

