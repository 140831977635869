.save {
    background: #3880F5;
    border-radius: 15px;
    font: normal normal 600 11px/15px 'Open Sans';
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 101px;
    height: 30px;
    cursor: pointer;
    opacity: 1;
}


.cancel {
    border: 1px solid #EBEBEB;
    border-radius: 15px;
    width: 82px;
    height: 30px;
    font: normal normal 600 11px/15px 'Open Sans';
    color: #386DFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}


.disabled{
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.5;
}
