.headerBox {
    /* width: 4.25rem;
    height: 2rem; */
    border: 1px solid #E1E8F4;
    border-radius: 0.25rem;
    background-color: #F8FAFD;
    border-color: #E1E8F4;
    cursor: pointer;
}
.headerBox.active{
    background-color: #E6F0FF;
}