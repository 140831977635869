.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(7, 22, 41, 0.85);
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  background-color: white;
  padding: 32px;
  border-radius: 10px;
  width: 700px;
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  justify-content: flex-end;
}

.mid {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.message {
  font-size: 14px;
  margin: 0 80px;
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn {
  border-radius: 20px;
  background-color: #186fd9;
  padding: 2px 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}

.cancel {
  border: 1px solid #EBEBEB;
  padding: 2px 20px;
  font-size: 14px;
  color: #386DFF;
  font-weight: bold;
  border-radius: 20px;
  cursor: pointer;
}