code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify .Toastify__toast-container {
  z-index: 999;
}

.popup-field {
  font: normal normal normal 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #707070;
}

.popup-field-value {
  font: normal normal 600 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #000000;
}

.flyout-asset {
  background-color: white;
  opacity: 1;
  padding: 10px;
  min-width: 20rem;
  border-radius: 10px;
}

.flyout-asset-gray {
  background-color: #E0E0E0;
  opacity: 1;
  padding: 10px;
  min-width: 20rem;
  border-radius: 10px;
}


.flyout-asset-title {
  background-color: white;
  opacity: 1;
  padding: 10px;
  border-radius: 10px;
}

.start_popup {
  background-color: white;
  opacity: 1;
  padding: 0.3rem;
  min-width: 14rem;
  font-size: 1rem;
  margin-left: 0px;
  margin-top: 5px;

}


.end_popup {
  background-color: white;
  opacity: 1;
  padding: 0.3rem;
  min-width: 18rem;
  margin-left: -20px;
  font-size: 1rem;
}


.park_popup {
  background-color: white;
  opacity: 1;
  padding: 0.3rem;
  min-width: 18rem;
  margin-left: -20px;
  font-size: 1rem;
  margin-top: 15px;


}