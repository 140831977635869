.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 1095px;
    max-height: 110vh;
}

.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    /* border-radius: 15px; */
    max-height: 110vh;
    overflow: hidden;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none;
}

.iconContainer {
    position: fixed;
    top: 8px;
    right: 30px;
    display: flex;
    z-index: 9999;
}

.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #000;
    padding: 5px;
    border-radius: 50%;
    transition: background 0.3s ease, color 0.3s ease;
}


.alias {
    font: normal normal 600 19px/13px Open Sans;
    color: #000000;
}

.headerTitle {
    font: normal normal bold 19px/13px Open Sans;
    color: #000000;
}

.reportNo {
    font: normal normal 600 19px/13px Open Sans;
    color: #000000;
}

.header {
    height: 37px;
    background: #EAEAEA;
    font: normal normal 600 9px/13px Open Sans;
    font-size: 10px;
    color: #000000;
}

.dayLabel {
    font: normal normal 600 10px/14px Open Sans;
    color: #050505;
}

.blackRow {
    background: #000000;
    height: 49px;
    font: normal normal bold 12px/17px Open Sans;
    color: #FFFFFF;
    text-transform: capitalize;
}

.coalRow{
    background: #000000;
    height: 30px;
    font: normal normal bold 12px/17px Open Sans;
    color: #FFFFFF;
    text-transform: capitalize;
}


.coalHeader {
    height: 30px;
    background: #EAEAEA;
    font: normal normal 600 9px/13px Open Sans;
    color: #000000;
}

.modalBody {
    max-height: 90vh;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modalBody::-webkit-scrollbar {
    display: none;
}

.data {
    font: normal normal normal 10px/10px Open Sans;
    color: #050505;
}

.ripple {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
    opacity: 0.25;
    background-color: black;
    z-index: 1000;
}
