/* Styles for the fixed table header */
.fixed-header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  /* Add any other styling you need for your header */
}

.height-calc {
  max-height: calc(100vh - 480px);
  overflow-y: scroll;
}

.height-calculate {
  max-height: calc(100vh - 250px);
  overflow-y: scroll;
}

.asm-height-calculate {
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}

.users_header {
  position: sticky;
  top: 0;
}

.prestart_header {
  position: sticky;
  top: 0;
  border-width: 0.5px 0px;
  border-color: #707070;
}

.align-padding {
  padding-left: 12px;
  padding-right: 12px;
}

.radio-sort {
  accent-color: #05795f;
  height: 14px;
  width: 14px;
}

.asm-text-1 {
  font: 'normal normal normal 12px/14px Open Sans';
  color: #092344;
  font-size: 13px;
}

.asm-text-2 {
  font-size: 12px;
}

.asm-hover-settings {
  font: normal normal 600 12px/14px Open Sans;
  color: black;
  transition: color 0.1s;
}

.asm-history-filter {
  font: normal normal 600 12px/14px Open Sans;
  color: black;
}

// .asm-hover-settings:hover {
//   font: normal normal 600 12px/14px Open Sans;
//   color: green !important;
// }

tbody>tr:hover {
  background-color: #f8fbff;
  cursor: pointer;
}

.table_text_blue {
  color: #2596be;
  cursor: pointer;
}

.image-click {
  cursor: pointer;
}

.manage-user-button-container {
  padding: 13px 15px 13px 0;
  display: flex;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  gap: 8px;
  padding-top: 10px;
}

div.modal-position {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 70%;
  max-width: 600px;
  font-size: 14px;

  div.modal-content {
    margin-top: 15vh;
    height: 83vh !important;
    overflow: auto;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
  }
}

.modal-transparent {
  background-color: #ffffff00;
}

.asm-settings-modal-transparent {
  background-color: #ffffff00;
}

.asm-modal-backgrop-style {
  background-color: rgba(0, 0, 0, 0);
}

.asm-settings-modal-backgrop-style {
  background-color: rgba(0, 0, 0, 0);
}

.asm-hover-card {
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.2),
    0 4px 6px -4px rgb(0 0 0 / 0.2);
  position: absolute;
  width: 100% !important;
  z-index: 9;
}

$report-filter-height: 6.3rem;

div.asm-report-filter-modal-position {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 65%;
  max-width: 570px;
  font-size: 0.8rem;

  div.modal-content {
    margin-top: $report-filter-height;
    height: calc(100vh - $report-filter-height) !important;
    overflow: auto;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  div.modal-content>div.modal-body {
    border-bottom: none;
    padding: 0px;

    height: calc(100vh - 17rem) !important;
    overflow: auto;
  }
}

$history-filter-height: 8.9rem;
$history-seetings-filter-height: 9.8rem;

div.asm-settings-modal-position {
  position: fixed !important;
  top: -3rem;
  right: 2rem;
  width: 75%;
  max-width: 23.3rem;
  font: normal normal 0.8rem Open Sans;
  border: none;

  div.modal-content {
    margin-top: $history-seetings-filter-height;
    height: 16rem !important;
    // height: calc(100vh - $history-seetings-filter-height) !important;
    overflow: hidden;
    // border-left: 10px solid #ebebeb;
    background-color: #FFFFFF;
    border-radius: 0.25rem !important;
    box-shadow: 0.063rem 0.375rem 0.625rem #00000029;
    border: 0.063rem solid #E1E8F4;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 1.625rem;
    padding-left: 0rem;
  }

  div.modal-content>div.modal-footer {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0;
      }

  div.modal-content>div.modal-body {
    border-bottom: none;
    padding: 0px;
    height: calc(100vh - 17rem) !important;
    // overflow: auto;
  }
}

.asm-timeline-graph-plus-button {
  border: 1px solid #e3e3e3;
  font-size: 10px;
  border-radius: 3px 0px 0px 3px;
  border-right: 0;
  padding: 0.4rem 0.6rem;
}

.asm-timeline-graph-minus-button {
  border: 1px solid #e3e3e3;
  font-size: 10px;
  border-radius: 0px 3px 3px 0px;
  border-left: 0;
  padding: 0.4rem 0.6rem;
}

div.asm-timeline-graph-modal-position {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  font: normal normal 0.8rem Open Sans;
  margin-bottom: 0;
  height: 40%;

  div.modal-content {
    margin-top: 0;
    height: 100vh !important;
    border-radius: 0;
    // height: calc(100vh - $history-filter-height) !important;
    overflow: auto;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  div.modal-content>div.modal-body {
    border-bottom: none;
    padding: 0px;
    height: calc(100vh - 17rem) !important;
    overflow: auto;
  }
}

.asm-timeline-graph-header {
  font: 'normal normal 600 14px/24px Open Sans;';
  color: '#000';
  text-transform: uppercase;
}

div.asm-filter-modal-position {
  position: fixed !important;
  top: 0;
  right: 0;
  width: 65%;
  max-width: 570px;
  font: normal normal 0.8rem Open Sans;

  div.modal-content {
    margin-top: $history-filter-height;
    height: calc(100vh - $history-filter-height) !important;
    overflow: auto;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  div.modal-content>div.modal-body {
    border-bottom: none;
    padding: 0px;

    height: calc(100vh - 17rem) !important;
    overflow: auto;
  }
}

.add-user-input>input:focus {
  outline: none;
  border-color: #0f614e;
}

.field-container {
  margin-left: 25px;
  font-size: 13px;
}

.add-user-input>input {
  border: 1px solid #e1e8f4;
  border-radius: 4px;
  padding: 7px;
}

.rounded-borders-and-padding {
  padding: 7px;
  border: 1px solid #e1e8f4;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  background-color: white;
  text-align: start;
  width: 100%;
}

.asm-no-focus-border:focus {
  outline: none;
}

.hover-card {
  background-color: white;
  box-shadow:
    0 10px 15px -3px rgb(0 0 0 / 0.2),
    0 4px 6px -4px rgb(0 0 0 / 0.2);
  position: absolute;
  width: 125% !important;
  z-index: 9;
}

.asm-options {
  max-height: 110px;
  overflow-y: scroll;
}

// .asm-hover-card ::-webkit-scrollbar {
//   display: none;
// }

.dropdown-position {
  left: 0;
  top: 37px;
}

.calendar-position {
  top: 40px;
}

.narrow-padding {
  padding: 5px;
}

.option-row {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 4px;
  cursor: pointer;
  padding: 0 7px;
}

.option-row:hover {
  background-color: #e1e8f4;
}

.option-row>input,
.option-row>label {
  cursor: pointer;
  // padding: 4px 7px;
}

.add-user-input {
  flex: 2;
}

.flex-one {
  padding: 7px;
  flex: 0.7;
  display: flex;
}

.switch-style {
  height: 100px;
}

.flex-two {
  flex: 2;
  padding: 7px;

  border: 1px solid #e1e8f4;
}

.switch-container {
  flex: 2;
}

.status-container {
  flex: 2;
}

// styling disbled input
input:disabled {
  background-color: #e1e4ea;
  color: #677789;
  padding: 7px;
}

.field-container>select:disabled {
  background-color: #e1e4ea;
  color: #677789;
}

div.modal-backgrop-style {
  background-color: greenyellow;
  display: none;
}

.dropdown-img {
  height: 11px;
  padding-right: 9px;
}

.dropdown-text {
  font-size: 13px;
}

.user-detail-text {
  font-size: 13px;
  color: #1a1a1a;
  font-weight: 600;
}

.user-label {
  width: 165px;
  font-size: 13px;
  padding: 5px 0px 5px 30px;
}

.user-detail-body {
  margin-top: -30px;
}

.button-press-change:hover {
  box-shadow: inset 0 0 3px black;
}

.button-press-change:active {
  box-shadow: inset 0 0 5px black;
}

.button-pressed {
  box-shadow: inset 0 0 5px black;
}

.fixed-width {
  min-width: 20px;
  min-height: 20px;
}

.status-toggle {
  width: 90px;
  font-size: 13px;
}

.small-btn-close {
  height: 5px !important;
  width: 5px !important;
  margin-left: 30px !important;
  margin-right: 20px !important;
}

.width-310px {
  width: 310px;
}

.rdp-day_today:not(.rdp-day_selected) {
  font-weight: bold !important;
  color: #186fd9 !important;
}

.table_asm_history {
  font-size: 13px;

  th {
    padding: 8px;
    padding-bottom: 10px;
    vertical-align: middle;
    // border-width: 0.5px 0px;
    // border-color: #707070;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  tbody {
    margin-top: 10px;
    /* Match the border-bottom width of the fixed header */
  }

  td {
    padding: 8px;
    vertical-align: middle;
    border-width: 0.5px 0px 0px 0px;
    border-color: #ddd;
  }

  .selected {
    td {
      padding: 8px;
      vertical-align: middle;
      border-width: 0.5px 0px;
      border-color: #3880f5;
      background-color: #f2f7ff;
    }
  }

  overflow-y: scroll;
  height: max-content;
}

.table_asm table thead th:first-child,
.table_asm table tbody td:first-child {
  width: 1%;
}

.table_asm table thead th:nth-child(2),
.table_asm table tbody td:nth-child(2) {
  width: 8%;
}

.table_asm table thead th:nth-child(3),
.table_asm table tbody td:nth-child(3) {
  width: 8%;
}

.table_asm table thead th:nth-child(4),
.table_asm table tbody td:nth-child(4) {
  width: 10%;
}

.table_asm table thead th:nth-child(5),
.table_asm table tbody td:nth-child(5) {
  width: 10%;
}

.table_asm table thead th:nth-child(6),
.table_asm table tbody td:nth-child(6) {
  width: 8%;
}

.table_asm table thead th:nth-child(7),
.table_asm table tbody td:nth-child(7) {
  width: 10%;
}

.table_asm table thead th:nth-child(8),
.table_asm table tbody td:nth-child(8) {
  width: 10%;
}

.table_asm table thead th:nth-child(9),
.table_asm table tbody td:nth-child(9) {
  width: 10%;
}

.table_asm table thead th:nth-child(10),
.table_asm table tbody td:nth-child(10) {
  width: 10%;
}

.table_asm table thead th:nth-child(11),
.table_asm table tbody td:nth-child(11) {
  width: 8%;
}