.shiftTotalCard {
  /* width: 580px; */
  height: max-content;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
}

.cardTitle {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
  text-transform: capitalize;
}

.shiftTotalLabel {
  font: normal normal normal 14px/19px Open Sans;
  color: #000000;
}

.activityCard {
  /* width: 301px; */
  height: 94px;
  background: #fafafa 0% 0% no-repeat padding-box;
  border-radius: 20px;
}

.activityLabel {
  font: normal normal normal 8px/11px Open Sans;
  color: #6f7070;
}

.activityTotal {
  font: normal normal 600 18px/24px Open Sans;
  color: #000000;
}

.activityTarget {
  font: normal normal normal 18px/24px Open Sans;
  color: #000000;
}

.activityStatusLabel {
  font: normal normal normal 10px/22px Open Sans;
  color: #696969;
}

.breakDownContainer {
  gap: 6px;
  width: 140px;
}

.breakDownLabel {
  font: normal normal normal 10px/14px Open Sans;
  color: #6f7070;
}

.breakLabel {
  font: normal normal 600 11px/15px Open Sans;
  color: #000000;
}

.breakValue {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
}

.rightContainer {
  width: 275px;
  background: #fafafa;
  border-radius: 15px;
}

.dayTotalLabel {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
  width: 33%;
}

.dayTotalActual {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
  /* margin-left: 25px */
}

.dayTotalTarget {
  font: normal normal normal 12px/17px Open Sans;
  color: #000000;
}
