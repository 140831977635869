.shift-history-menu {
  color: gray;
  display: flex;
  font-size: 1.2rem;
  flex-direction: row;
  font-weight: 600;
  gap: 30px;
}

.shift-history-item.active {
  color: #2b7bdc;
  border-bottom: #2b7bdc 2px solid;
}

.shift-history-toolbar {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: end;
}

.shift-history-table {
  color: black;

  font-size: 1rem;
  width: 100%;
}

.shift-history-table thead {
  color: darkgray;
  border: 1px solid #e1e8f4;
  font: normal normal 600 12px/17px;
  font-size: 1rem;
  color: #576b8d;
}

.shift-history-table td {
  /* text-align: center; */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.shift-history-table th:first-child {
  padding-left: 4rem;
}

.shift-history-table td:last-child {
  padding-right: 2rem;
}

.shift-history-table tbody tr {
  border-bottom: lightgray 1px solid;
}

.shift-history-table tbody tr[hover]:hover {
  background-color: #e2ecfc;
  cursor: pointer;
}

.shift-history-hover {
  opacity: 0.5;
}

.shift-history-table tr:hover .shift-history-hover {
  opacity: 1;
}

.shift-history-modal {
  background-color: white;
  box-shadow: lightgray 0px 0px 10px 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  height: 100%;
  width: 512px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
.hide-clock::-webkit-calendar-picker-indicator {
    /* background: none; */
}

.trips-history {
  background-color: white;
  padding: 1.25rem 1.875rem 0rem 1.875rem;
}
.trips-history-toolbar {
  display: flex;
  flex-direction: row;
  gap: 0.9rem;
  align-items: center;
  justify-content: flex-start;
  z-index: 2;
  margin-bottom: 1rem;
}
.search-asset {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgb(225, 232, 244);
  padding: 0.25rem 0.75rem;
  border-radius: 1.5rem;
  width: 15rem;
  background-color: white;
}
.legend-box2 {
  width: 0.66rem;
  height: 1.42rem;
  margin-right: 0.625rem;
}

.legend-box1 {
  position: relative;
  width: 0.66rem;
  height: 1.42rem;
}

.legend-box1::before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 0.5rem solid transparent; /* Left Triangle */
  border-right: 0.5rem solid transparent; /* Right Triangle */
  border-bottom: 0.5rem solid #fff; /* Color of the bookmark */
  bottom: -2rem; /* Adjust the distance from the bottom as needed */
  left: 50%;
  bottom: 0%;
  transform: translateX(-50%); /* Center the triangle */
}

.legend_dropdown {
  position: absolute;
  left: 3rem !important;
  top: 125% !important;
  box-shadow: -1px 1px 6px #0000001a;
  width: max-content;
  transform: translateX(-60%) !important;
}
/* dropdown-styling-override */
.trips-history .css-1r4vtzz {
  background: #fafafa 0% 0% no-repeat padding-box !important;
  height: 1.5rem !important;
  border-radius: 6.25rem !important;
  box-shadow: none !important;
  border: 0.063rem solid #ebebeb !important;
  color: black !important;
  padding: 0rem 0.5rem 0rem 0.5rem !important;
  width: 6.063rem !important;
  margin-left: 1rem !important;
  font: normal normal 600 1.075rem/0.875rem Open Sans !important;
  margin-bottom: 0.3rem !important;
  /* margin-right: 0.5rem !important; */
}
.trips-history .css-48ayfv {
  background: #fafafa 0% 0% no-repeat padding-box !important;
  height: 1.5rem !important;
  border-radius: 6.25rem !important;
  box-shadow: none !important;
  border: 0.063rem solid #ebebeb !important;
  color: black !important;
  padding: 0rem 0.5rem 0rem 0.5rem !important;
  width: 6.063rem !important;
  margin-left: 1rem !important;
  font: normal normal 600 1.075rem/0.875rem Open Sans !important;
  margin-bottom: 0.3rem !important;
  /* margin-right: 0.5rem !important; */
}
.filter-dropdown {
  font: normal normal normal 1.075rem/0.875rem "Open Sans";
  margin-top: 0.3rem;
  margin-left: 0.5rem;
  color: #2b2d2f;
  white-space: nowrap;
}
