.filterBtn {
    border: 1px solid #e1e8f4;
    border-radius: 2rem;
    color: #1a1a1a;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    gap: 2px;
    cursor: pointer;
}



.downloadIcon {
    width: 30px;
    height: 30px
}

.dateTimeSelect input {
    color: #6b7b8e !important;
  }

  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: #8190ad;
    background-color: #e1e4ea;
  }
  
  
  