.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.card {
  border-bottom: 1px solid #e1e8f4;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.date {
  font-size: 12px;
  color: #6a7c9a;
  font-weight: 600;
  font-style: italic;
}

.changesCard {
  color: #1a1a1a;
  font-size: 12px;
  line-height: 22.5px;
  display: flex;
  gap: 10px;
}

.key {
  font-weight: bold;
  min-width: max-content;
}
