@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #000;
  padding: 5px;
  border-radius: 50%;
  transition:
    background 0.3s ease,
    color 0.3s ease;
}

.download-icon-report {
  display: flex;
  justify-content: flex-end;
  margin-top: 13px;
  margin-right: 30px;
}

#report-outer-view {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

#report-outer-view::-webkit-scrollbar {
  width: 0px;
}

.reportViewPdf {
  background-color: #fff;
  margin: 20px auto;
  height: 935px;
  width: 800px;
}

.input-sign {
  border: 1px solid #ebebeb;
}

/* CSS for DynamicTable component */
.table-item {
  padding: 10px;
  word-wrap: break-word;
  /* Word will be wrapped to fit into the container */
  white-space: normal;
  /* Allow wrapping long lines */
  overflow: hidden;
  /* Hide overflowing content */
}

.border-y {
  border-top: 0.08rem solid #ebebeb;
  border-bottom: 0.08rem solid #ebebeb;
}

.report-view::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

.report-view::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.reportViewPdf::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}

.reportViewPdf::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.border-y-solid {
  border-top: 0.08rem solid #707070;
  border-bottom: 0.08rem solid #707070;
}

.border-r {
  border-right: 0.08rem solid #ebebeb;
}

.border-el {
  border-top: 0.08rem solid #707070;
  border-bottom: 0.08rem solid #707070;
  border-right: 0.08rem solid #707070;
}

.pady-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.color-success {
  color: #2a7b39;
}

.color-faulty {
  color: #aa7827;
}

/* -----------------new css----------------- */

.logoImos {
  width: 90px;
  height: 30px;
}

.assetIdLabel {
  font: normal normal bold 20px/27px Open Sans;
  color: #1a1a1a;
  text-transform: uppercase;
}

.reportLabel {
  font: normal normal normal 20px/27px Open Sans;
  color: #1a1a1a;
  text-transform: capitalize;
}

.dateLabel {
  font: normal normal normal 14px/19px Open Sans;
  color: #1a1a1a;
  text-transform: uppercase;
}

.checkSummary {
  font: normal normal bold 16px/22px Open Sans;
  color: #1a1a1a;
}

.dateLabelSummary {
  font: normal normal normal 12px/17px Open Sans;
  color: #1a1a1a;
}

.jobWOLabel {
  font: normal normal normal 16px/22px Open Sans;
  color: #1a1a1a;
}

.jobWOData {
  font: normal normal bold 16px/22px Open Sans;
  color: #1a1a1a;
}

.reportSection {
  background: #f8fafd;
}

.dataLabel {
  font: normal normal normal 12px/17px Open Sans;
  color: #1a1a1a;
}

.data {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
}

.borderY {
  border-top: 1px solid #e1e8f4;
  border-bottom: 1px solid #e1e8f4;
}

.borderB {
  border-bottom: 1px solid #e1e8f4;
}

.supervisorUse {
  font: normal normal bold 12px/17px Open Sans;
  color: #1a1a1a;
}

.comments {
  font: normal normal normal 12px/17px Open Sans;
  color: #000000;
}

.signature {
  font: normal normal normal 12px/17px Open Sans;
  color: #000000;
}

.saveBtn {
  background: #186fd9;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #ffffff;
  width: 136px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.disabledSaveBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e1e4ea;
  border-radius: 15px;
  width: 136px;
  height: 30px;
  font: normal normal bold 12px/17px Open Sans;
  color: #8190ad;
  pointer-events: none;
  cursor: not-allowed;
}

.addSignature {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e1e8f4;
  border-radius: 15px;
  width: 124px;
  height: 30px;
  font: normal normal bold 12px/17px Open Sans;
  color: #186fd9;
  cursor: pointer;
}

.notifyOperator {
  font: normal normal bold 12px/17px Open Sans;
}

.notifyInfo {
  font: normal normal normal 12px/17px Open Sans;
}

.notifyBtnDisabled {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 30px;
  background: #e1e4ea;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #8190ad;
  pointer-events: none;
  cursor: not-allowed;
}

.notifyBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e1e8f4;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #186fd9;
  cursor: pointer;
}

.sendNotificationBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #186fd9;
  border: 1px solid #186fd9;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  width: 136px;
  height: 30px;
}

.cancelBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e8f4;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #186fd9;
  cursor: pointer;
}

.checkListLabel {
  font: normal normal bold 12px/17px Open Sans;
  color: #000000;
}

.categoryLabel {
  font: normal normal bold 12px/17px Open Sans;
  color: #000000;
}

.categoryBg {
  background: #eff3fa;
}

.resultBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  font:
    normal normal bold 16px/22px 'Open Sans',
    sans-serif;
  border-radius: 27px;
  width: 142px;
  height: 40px;
}

.ripple {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  opacity: 0.25;
  background-color: black;
  z-index: 1000;
}
