.mainContainer {
    position: relative;
    user-select: none;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    aspect-ratio: 1/1;
    border-radius: 15px;
}

.iconContainer:hover {
    background-color: #0981FC0D;
}

.iconContainer:active {
    background-color: #0981FC0D;
}

.optionsContainer {
    position: absolute;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 45px #A3A3A32B;
    border-radius: 15px;
    z-index: 100;
    right: 0;
    top: 100%;
    padding: 6px 6px;
}

.option {
    min-width: 140px;
    text-align: start;
    color: #313131;
    user-select: none;
    padding: 4px 20px;
    font:normal normal normal 11px/15px Open Sans;
    cursor: pointer;
    border-radius: 15px;
}

.option:hover {
    background-color: #0981FC0D;
}

.disabled {
    cursor: not-allowed;
    color: #8190AD;
    /* background-color: #E1E4EA; */
}

.disabled:hover {
    background-color: #E1E4EA;
}