.container {
  /* width: 164px; */
  height: 255px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
}

.bottomContainer {
  /* width: 164px; */
  height: 80px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
}

.title {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
}

.labelCoal {
  font: normal normal 600 11px/15px Open Sans;
  color: #000000;
}

.valCoal {
  font: normal normal 600 15px/20px Open Sans;
  color: #000000;
}

.labelROM {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
}

.valROM {
  font: normal normal 600 18px/24px Open Sans;
  color: #000000;
}
