.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #eff3fa;
}

.textInputField {
  width: 320px;
  border-radius: 15px;
  height: 30px;
  font-size: 12.5px;
}

.selectField {
  background-color: white;
  height: 30px;
  border-radius: 15px;
  width: 320px;
  font-size: 12.5px;
}

.optionContainer {
  display: flex;
  align-items: center;
}

.optionLabel {
  margin-left: 8px;
  font-size: 14px;
}

.dropdownContainer {
  position: relative;
  width: 100%;
}

.menu {
  background-color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  box-shadow: 1px 6px 20px #00000029;
  margin-top: 4px;
  border: 1px solid #e1e8f4;
  position: absolute;
  z-index: 2;
  width: 100%;
}
