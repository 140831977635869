.truckCardDetail {

  padding: 5px 26px 13px 26px;
  font: normal normal 600 12px/24px Open Sans;
}

.excTruckDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.truckId {
  font: normal normal 600 12px/24px Open Sans;
}

.loadsText {
  font: normal normal 600 12px/14px Open Sans;
}

.operatorName {
  text-align: left;
  font: normal normal normal 11px/15px Open Sans;
  color: #6f7070;
  opacity: 1;
}

.loadBadge {
  text-align: center;
  font: normal normal normal 9px/12px Open Sans;
  background: #d8e5ff 0% 0% no-repeat padding-box;
  border-radius: 15px;
  width: 67px;
  display: inline-block;
  padding: 0.25em 0.4em;
}

.loadStateIcon {
  width: 10px;
  height: 10px;
  display: inline-block;
  background-image: url("/src/assets/svgs/truckloads/Group\ 73404.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
