.flyer_modal::-webkit-scrollbar {
  display: none !important;
}

.btn.disabled {
  background-color: #e1e4eb;
  color: #8190ad;
  cursor: not-allowed;
}

.btn:active {
  background-color: #1b89ff;
}

.btn.disabled:active {
  background-color: #e1e4eb;
  color: #8190ad;
}

.btn.outline {
  border: 1.4px solid #e1e8f4;
  background-color: white;
  color: #186fd9;
}

.btn.outline.disabled {
  color: #8190ad;
  background-color: white;
}

.outline:active {
  background-color: #f0f4f9;
}

.btn.outline.disabled:active {
  color: #8190ad;
  background-color: white;
}

.close {
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
}

.close:hover {
  background-color: #e2ecfb;
}

.close:focus {
  background-color: #b5c7e2;
}
