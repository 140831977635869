.container {
  border: 1px solid #e1e8f4;
  display: flex;
  border-radius: 9px;
  flex-direction: column;
  margin: 10px 0px;
  overflow: auto;
  flex-grow: 1;
  position: relative;
}

.heading {
  background-color: #f0f4fa;
  color: #1a1a1a;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 19px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e1e8f4;
  border-bottom: 1px solid #e1e8f4;
}

.button {
  font-size: 12.5px;
  font-weight: bold;
  color: #8190ad;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0px 14px;
  user-select: none;
  background-color: #e1e8f4;
  cursor: pointer;
}

.button:hover {
  color: #1a1a1a;
  background-color: #b6c7e2;
}

.headersContainer {
  display: flex;
  width: 100%;
  padding: 8px 19px;
  border-top: 1px solid #e1e8f4;
  border-bottom: 1px solid #e1e8f4;
}

.header {
  /* border: 1px solid red; */
  color: #576b8d;
  font-size: 12px;
  font-weight: 600;
  padding-left: 20px;
}

.tableRow {
  background-color: #f8fafd;
  border-top: 1px solid #e1e8f4;
  border-bottom: 1px solid #e1e8f4;
  padding: 8px 19px;
  display: flex;
  cursor: pointer;
}

.tableRow:hover {
  background-color: #e2ecfc;
}

.tableRow:focus {
  border-top: 1px solid #186fd9;
  border-bottom: 1px solid #186fd9;
  background-color: #e2ecfc;
}

.tableTruckRow {
  background-color: white;
}

.dataCell {
  /* border: 1px solid red; */
  width: 10%;
  font-size: 14px;
  color: #1a1a1a;
  padding-left: 20px;
}

.actions {
  /* border: 1px solid black; */
  margin-left: auto;
  width: 15%;
  max-width: fit-content;
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
}

.actions>div {
  display: flex;
  align-items: center;
}

.kebab {
  position: relative;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}

.menu {
  position: absolute;
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  background-color: white;
  top: 0;
  right: 20px;
  align-items: flex-start;
  box-shadow: 1px 3px 4px #00000029;
  border: 1px solid #e1e8f4;
  border-radius: 4px;
  overflow: hidden;

  div {
    padding: 3px 10px;
    font-weight: normal;
    font-size: 12px;
    color: #1a1a1a;
    width: 100%;
    cursor: pointer;
  }

  div:hover {
    background-color: #e1e8f4;
  }
}