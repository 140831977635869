
.box-temp{
    background-color: red;
}

/* input[type=checkbox] {
    border: solid red; */
    /* outline: 2px solid #E35656; */
    /* border-radius: 3px;
    accent-color: #E35656;
    background: #E35656;
    opacity: 1;
  } */

  