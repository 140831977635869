.close {
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
}

.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 470px;
    max-height: 110vh;
}

.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    border-radius: 10px;
    max-height: 110vh;
    overflow: hidden;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none;
}

.label {
    font: normal normal normal 14px/17px Open Sans;
    color: #1A1A1A;
}

.title {
    font: normal normal bold 18px/24px Open Sans;
    color: #000000;
}

.saveChanges {
    width: 122px;
    height: 30px;
    font: normal normal bold 12px/17px Open Sans;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}