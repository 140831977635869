/* Tooltip styling */
.customTooltip {
  position: absolute;
  top: 38px; /* Adjust to control distance from label */
  left: 0;
  background-color: #ffffff;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  padding: 12px;
  z-index: 10;
  width: 180px;
  opacity: 0.9;
}

/* Tooltip title styling */
.tooltipTitle {
  font: normal normal 600 12px/24px Open Sans;
  color: #000000;
}

/* Tooltip content */
.tooltipContent {
  font: normal normal normal 9px/24px Open Sans;
  color: #646464;
}

.tooltipData {
  width: 100%;
  font: normal normal normal 11px/14px Open Sans;
  color: #000000;
  width: 165px;
}
