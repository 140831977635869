.subheader {
  // background-color: #fff;
  justify-content: flex-end;
  padding: 10px 0px 0px 0px;
  align-items: center;
}

.filter-div {
  display: flex;
}
.subheader-download {
  padding: 4px;
  margin-left: 13px;
}
.search-input {
  border: 1px solid #dde5f3;
  border-radius: 1.66rem;
  padding-left: 0.85rem;
  color: #333;
  font-size: 13px;
  padding-right: 2.5rem;
  height: 30px;
  width: 197px;
  display: flex;
}

.date-input {
  height: 30px;
    border: 1px solid #dde5f3;
    border-radius: 0.5rem !important;
    color: #333;
    font-size: 1rem;
    padding: 0.35rem !important;
 
}


.dropdown-input {
  height: 30px;
  border: 1px solid #dde5f3;
  border-radius: 0.5rem !important;
  color: #333;
  font-size: 1rem;
  padding: 0.35rem !important;
}

.inner-date-input {
  height: 24px ;
  padding-left: 5px !important;
  border-Radius: 20px !important;
  margin: 0px;
  min-width: 120px;
  background-color: #FAFAFA !important;
}

.inner-dept-input {
  height: 24px ;
  padding-left: 5px !important;
  border-Radius: 20px !important;
  margin: 0px;
  min-width: 170px;
  background-color: #FAFAFA !important;
}


.search-input::placeholder {
  color: #707070;
  position: absolute;
  top : 15%;
  font-size: 13px;
}

.search-input:focus {
  border: 1px solid #dde5f3;
  outline: none;
}
.search-icon {
  margin-left: -30px;
  cursor: pointer;
}
