/* Default styles for all screen sizes */
/* @media (min-width:992px) {
  .col-lg-5 {
    padding-left: 0;

  }
} */

@media (max-width: 48rem) {
  .element-1 {
    padding-left: 0.938rem;
    margin-bottom: 0.625rem;
  }

  .element-2 {
    padding-left: 0.938rem;
  }
}

@media (min-width: 48rem) and (max-width: 62rem) {
  .element-1 {
    /* padding-bottom: 0.625rem; */
  }
}
.width {
  width: auto;
}
@media (max-width: 67.5rem) {
  .width {
    width: 100%;
  }
}

@media (min-width: 62rem) {
  .element-2 {
    padding-left: 0.313rem;
  }
  .element-1 {
    padding-right: 0.313rem;
    /* padding-bottom: 0.625rem; */
  }
}

.delaysToggleButton {
  height: 1.8rem;
  min-width: 6.875rem;
  font-style: italic;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  color: #576b8d;
  border: 0.063rem solid;
  border: 0.063rem solid #e1e8f4;
  border-radius: 0.8125rem;
  opacity: 1;
}

.delaysToggleButton.active {
  background: #14233c 0% 0% no-repeat padding-box;
  color: white;
  border: none;
}

.dealysValue {
  font-size: 0.625rem;
  color: #1a1a1a;
  font-weight: bold;
}

.delaysViewDetailsrow {
  line-height: 2;
}
.delaysViewDetailsLabel {
  font-size: 0.725rem;
  font-weight: 600;
  color: #5f6672;
  opacity: 1;
}

.delaysViewDetailstoogleButton {
  margin-top: 1.563rem;
  margin-bottom: 1.25rem;
}

.wdChart {
  color: #5f6672;
  line-height: 1.5;
  font-size: 0.625rem;
  font-weight: 600;
}
.wdChartColor {
  border-radius: 50%;
  width: 0.375rem;
  height: 0.375rem;
  margin-right: 0.313rem;
}

.wdViewDetaislFR-box {
  width: 3.25rem;
  height: 0.938rem;
  background-color: #fce5e5;
  border-radius: 0.813rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wdViewDetaislFR-text {
  color: #aa2727;
  font-size: 0.625rem;
  max-width: 3.25rem;
}

.wdViewDetails-trucks {
  font-size: 0.75rem;
  color: #576b8d;
  font-style: italic;
  opacity: 1;
}

.lh-2 {
  line-height: 2;
}

.lh-1 {
  line-height: 1.3;
}

.icons {
  cursor: pointer;
}
.icons li {
  background: none repeat scroll 0 0 black;
  height: 0.25rem;
  width: 0.25rem;
  margin-top: 0.3rem;
  vertical-align: top;
  border-radius: 50%;
}

/* WDBox.css */

/* Outer container styles */
.wd-box {
  border: 0.0625rem solid #e1e8f4;
  border-color: #e1e8f4;
  border-radius: 0.375rem;
  min-width: 12rem;
  font-size: 0.8rem;
  margin-right: 0.8rem;
}

/* Row container styles */
.wd-box-row {
  width: 100%;
  height: 0.9375rem;
  padding-right: 1.5625rem;
}

/* Indicator dot styles */
.indicator-dot {
  background-color: #367ed8;
  border-radius: 50%;
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 0.3125rem;
  padding: 0;
}

/* Label styles */
.label {
  color: #5f6672;
  font-weight: 600;
  width: 5rem;
}

/* Value styles */
.value {
  color: black;
  font-weight: 700;
}
/* Time indicator styles */
.time-indicator {
  background-color: #e2fdf1;
  color: #1a6c29;
  border-radius: 10px;
  height: 1rem;
  width: 3.25rem;
}

.hover-blue:hover {
  background-color: #F2F7FF;
}
