.button {
  font-size: 12.5px;
  font-weight: bold;
  color: #8190ad;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 0px 14px;
  user-select: none;
  background-color: #e1e8f4;
  cursor: pointer;
}

.button:hover {
  color: #1a1a1a;
  background-color: #b6c7e2;
}

.button:focus {
  background-color: #E2ECFC;
  color: #1A1A1A;
  border: 1.4px solid #B6C7E2;
}

.button:active {
  background-color: #E2ECFC;
  color: #1A1A1A;
  border: 1.4px solid #B6C7E2;
}