.inner-input-select {
  height: 1.438rem;
  border-radius: 1.125rem !important;
  margin-bottom: 0.06rem !important;
  margin-top: 0.04rem !important;
  background: #fff 0% 0% no-repeat padding-box;
}

.field-container {
  margin-left: 1.563rem;
  font-size: 0.813rem;
}

.rounded-borders-and-padding {
  padding: 0.438rem;
  border: 0.063rem solid rgb(225, 232, 244);
  font: normal normal normal 400 1.075rem/0.875rem "Open Sans";
  border-radius: 0.25rem;
  box-sizing: border-box;
  resize: vertical;
  text-align: start;
  width: 100%;
}

.small-rounded-borders-and-padding {
  border: 0.063rem solid #e1e8f4;
  border-radius: 0.25rem;
  box-sizing: border-box;
  resize: vertical;
  background-color: white;
  text-align: start;
  width: 100%;
}

.no-focus-border:focus {
  outline: none;
}

.hover-card {
  background-color: white;
  box-shadow: 1px 6px 10px #00000029;
  /* box-shadow: 0.043rem 0rem 0.425rem; */
  position: absolute;

  /* width: 25rem;
  height: 11rem; */
  z-index: 9;
  border-radius: 0.25rem;

  background: #ffffff 0% 0% no-repeat padding-box;
}

.hover-card label {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-position {
  left: 0;
  top: 2rem;
  min-width: 12rem;
}

.option-row {
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  padding: 0.1rem 0.9rem;
  font-size: 0.8rem;
}

.option-row:hover {
  background-color: #e1e8f4;
}

.option-row>input,
.option-row>label {
  cursor: pointer;
  padding: 0.25rem 0.438rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

.add-user-input {
  flex: 1;
}

.filter-dropdown-options {
  font-size: 0.8rem;
  height: 2rem;
  border-radius: 1.125rem;
  color: black;
  /* padding: 0 0.438rem; */
  width: 100%;
  margin-left: 0.3rem;
  margin-right: 0.5rem;
  font: normal normal normal 600 1.075rem/0.875rem "Open Sans";
}

.styled-checkbox {
  position: absolute;
  opacity: 0;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  &+label:before {
    content: "";
    margin-right: 0.625rem;
    display: inline-block;
    width: 100%;
    align-items: center;
    vertical-align: text-top;
    width: 1.5rem;
    height: 1.5rem;
    background: white;
    border: 1px solid #707070;
  }

  &:hover+label:before {
    background: green;
  }

  &:focus+label:before {
    box-shadow: 0 0 0 0.188rem rgba(0, 0, 0, 0.12);
  }

  &:checked+label:before {
    background: green;
  }

  &:disabled+label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled+label:before {
    box-shadow: none;
    background: #ddd;
  }

  &:checked+label:after {
    content: "";
    position: absolute;
    left: 0.4rem;
    /* 5px / 16px = 0.3125rem */
    top: 0.6525rem;
    /* 9px / 16px = 0.5625rem */
    background: white;
    width: 0.125rem;
    /* 2px / 16px = 0.125rem */
    height: 0.125rem;
    /* 2px / 16px = 0.125rem */
    box-shadow: 0.125rem 0 0 white, 0.25rem 0 0 white, 0.25rem -0.125rem 0 white,
      0.25rem -0.25rem 0 white, 0.25rem -0.375rem 0 white,
      0.25rem -0.5rem 0 white;
    /* Adjusted box-shadow values */
    transform: rotate(45deg);
  }
}

.asm-timeline-graph-plus-button {
  border: 1px solid #e3e3e3;
  font-size: 10px;
  border-radius: 3px 0px 0px 3px;
  border-right: 0;
  padding: 0.4rem;
}

.asm-timeline-graph-minus-button {
  border: 1px solid #e3e3e3;
  font-size: 10px;
  border-radius: 0px 3px 3px 0px;
  border-left: 0;
  padding: 0.4rem;
}

div.asm-timeline-graph-modal-position {
  position: fixed !important;
  bottom: 0;
  width: 100%;
  max-width: 100%;
  font: normal normal 0.8rem Open Sans;
  margin-bottom: 0;
  height: 40%;

  div.modal-content {
    margin-top: 0;
    height: 100vh !important;
    border-radius: 0;
    /* height: calc(100vh - $history-filter-height) !important; */
    overflow: auto;
  }

  div.modal-content>div.modal-header {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  div.modal-content>div.modal-body {
    border-bottom: none;
    padding: 0px;
    height: calc(100vh - 17rem) !important;
    overflow: auto;
  }
}

.asm-timeline-graph-header {
  font: 'normal normal 600 14px/24px Open Sans;';
  color: '#000';
  text-transform: uppercase;
}

.primary-color-border {
  border-color: var(--e-global-color-blue-button-color) !important;
}
.primary-color-outline {
  outline-color: var(--e-global-color-blue-button-color) !important;
}