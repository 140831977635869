.filter-main-label {
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
}
.filter-header {
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 600;
}
.custom-control-input {
  background-color: red;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: transparent;
  content: url(../../dropdown.svg);
  width: 20px;
  height: 20px;
  border-width: thin;
  cursor: pointer;
}
input[type="date"] {
  background-color: #fff;
  border: 1px solid #e1e8f4;
  border-radius: 5px;
  padding: 8px 5px;
  width: 150px;
}

input[type="date"]:focus {
  outline: none;
}
