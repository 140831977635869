.modalTop {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}

.modalTopArea {
    max-width: 787px;
    max-height: 110vh;
}

.modalContent {
    background: #FFFFFF;
    box-shadow: 0px 0px 45px #A3A3A329;
    border-radius: 15px;
    max-height: 110vh;
    overflow: hidden;
}

.modalHeader {
    padding: 30px 0 10px 0;
    border: none;
}

.modalBody {
    max-height: 75vh;
}

.closeIcon {
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px,
}

.title {
    font: normal normal 600 12px/22px Open Sans;
    color: #576B8D;
}

.totalDelayLabel {
    font: normal normal 600 14px/22px Open Sans;
    color: #1A1A1A;
}

.totalDelayValue {
    font: normal normal bold 14px/22px Open Sans;
    color: #1A1A1A;
}

.dayLabel {
    font: normal normal 600 11px/15px Open Sans;
    color: #000000;
    border: 1px solid #D6D6D6;
    border-radius: 15px;
    width: 113px;
    height: 24px;
}

.nightLabel {
    font: normal normal 600 11px/15px Open Sans;
    color: #FFFFFF;
    background: #000000;
    border-radius: 15px;
}

.typeContainer {
    width: 732px;
    height: 96px;
    background: #FAFAFA;
    border-radius: 15px;
}

.types {
    font: normal normal 600 10px/14px Open Sans;
    color: #5F6672;
}

.typesValue {
    font: normal normal 600 10px/14px Open Sans;
    color: #1A1A1A;
}

.tableHeader {
    font: normal normal 600 10px/22px Open Sans;
    color: #576B8D;
}

.tableValue{
    font: normal normal normal 12px/22px Open Sans;
color: #1A1A1A;
}
.width10{
    width: 10%;
}