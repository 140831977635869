.trigger {
  background-color: #eff3fa;
  color: #1a1a1a;
  font: normal normal bold 12px/17px Open Sans;
  border-radius: 20px;
  padding: 8px 20px;
  justify-content: space-between;
  cursor: pointer;
}

.showContent {
  background-color: #eff3fa;
}
