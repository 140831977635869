.delayCheckbox {
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background: #f4f6fa;
  border: 1px solid #e1e8f4;
  border-radius: 2px;
  position: relative;
  opacity: 1;
  cursor: pointer;
}

.flyer ul {
  border-bottom: none !important;
}
.delayCheckbox:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0rem;
  left: 0.45rem;
  width: 0.375rem;
  height: 1rem;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(45deg);
}

.delayCheckbox:checked {
  background: #186fd9;
  outline: none;
  border: 1px solid #e1e8f4;
}

.delayCheckbox:checked:after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.checkLabel {
  font: normal normal bold 14px/17px Open Sans;
  color: #1a1a1a;
}

.addDelayBtn {
  background: #186fd9;
  border-radius: 15px;
  font: normal normal bold 12px/17px Open Sans;
  color: #ffffff;
  cursor: pointer;
}
