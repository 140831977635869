.filterBtnApprove {
    border: 1px solid #e1e8f4;
    border-radius: 2rem;
    color: #1a1a1a;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    gap: 2px;
    cursor: pointer;
}



.downloadIconApprove {
    width: 30px;
    height: 30px
}

.addJob {
    width: 86px;
    height: 30px;
    background: #186FD9 0% 0% no-repeat padding-box;
    border-radius: 15px;
    opacity: 1;
    font: normal normal bold 12px/17px Open Sans;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}