  .loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

.user-pin {
    font-weight: 600;
    color: #186FD9;
    cursor: pointer;
}

.user-checkbox {
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  background: transparent;
  border: 0.0625rem solid #DDE5F3;
  border-radius: 2px;
  position: relative;
  opacity: 1;
  cursor: pointer;
}

.user-checkbox:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0rem;
  left: 0.45rem;
  width: 0.375rem;
  height: 1rem;
  border-bottom: 2px solid transparent;
  border-right: 2px solid transparent;
  transform: rotate(45deg);
}

.user-checkbox:checked {
  background: #186FD9;
  outline: none;
  border: 0.1rem solid #DDE5F3;
}

.user-checkbox:checked:after {
  border-bottom-color: #fff;
  border-right-color: #fff;
}

.action-popup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 0.75rem;
}

.action-popup-trigger {
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.user-action-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 100%;
  background-color: white;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.user-action-popup-item {
  padding-left: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  cursor: pointer;
}

.user-action-popup-item:hover {
  background-color: #f0f0f0;
}

.infinite-scroll-component__outerdiv:has(.user-table-body) {
  height: calc(100vh - 14.5rem) !important;
  overflow: hidden !important;
}


.manage-user-action-menu {
  margin-right: 1.5rem;
  margin-top: 0.2rem;
}


.table_manage_user table thead {
  position: sticky;
  top: 0;
  z-index: 99999;
  background: #f2f7ff !important;
}

.table_manage_user table tbody tr {
  height: 2.563rem !important;
}

.table_manage_user table thead tr th,
.table_manage_user table tbody tr td {
  padding: 0.75rem !important;
  line-height: normal !important;
}

.table_manage_user table thead tr {
  background-color: #FFFFFF !important;
  border-radius: 0.5625rem 0.5625rem 0 0 !important;
  height: 5rem !important;
  font: normal normal bold 12px/18px Open Sans !important;
  border-top: solid 0.1rem #ebebeb !important;
  border-bottom: solid 0.1rem #ebebeb !important;
}

.table_manage_user .user-table-row {
  border-radius: 0.5625rem 0.5625rem 0 0 !important;
  height: 2.563rem !important;
  font: normal normal normal 12px/18px Open Sans !important;
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
}
.table_manage_user .user-table-row-active {
  position: relative;
  z-index: 1;
  font: normal normal normal 12px/18px Open Sans !important;
  border-bottom:1px solid #186FD9;
  border-top:2px solid #a3c6ff;
  background-color: #F2F7FF;
}

.user-table-row-active td {
  position: relative;
  background-color: inherit;
}

.table_manage_user .user-table-body {
  margin-right: 1.75rem !important;
  /* max-height: calc(100vh - 16.9rem) !important; */
  /* overflow-y: scroll !important; */
  background-color: #FFFFFF !important;
  border-bottom: solid 0.1rem var(--e-global-before-light-border-color);
  border-top: solid 0.1rem var(--e-global-before-light-border-color);
  /* scrollbar-width: none !important; */
}

/* .table_manage_user .user-table-body::-webkit-scrollbar {
  display: none !important;
} */

.table_manage_user table tr td .table_text_blue {
  font-weight: 700 !important;
  color: var(--e-global-color-blue-button-color) !important;
}

.table_manage_user table thead tr th:first-child,
.table_manage_user table tbody tr td:first-child {
  width: 2% !important;
}

.table_manage_user table thead tr th:nth-child(2),
.table_manage_user table tbody tr td:nth-child(2) {
  width: 12% !important;
}

.table_manage_user table thead tr th:nth-child(3),
.table_manage_user table tbody tr td:nth-child(3) {
  width: 8% !important;
}

.table_manage_user table thead tr th:nth-child(4),
.table_manage_user table tbody tr td:nth-child(4) {
  width: 9% !important;
}

.table_manage_user table thead tr th:nth-child(5),
.table_manage_user table tbody tr td:nth-child(5) {
  width: 12% !important;
}

.table_manage_user table thead tr th:nth-child(6),
.table_manage_user table tbody tr td:nth-child(6) {
  width: 8% !important;
}

.table_manage_user table thead tr th:nth-child(7),
.table_manage_user table tbody tr td:nth-child(7) {
  width: 8% !important;
}

.table_manage_user table thead tr th:nth-child(8),
.table_manage_user table tbody tr td:nth-child(8) {
  width: 15% !important;
}

.table_manage_user table thead tr th:nth-child(9),
.table_manage_user table tbody tr td:nth-child(9) {
  width: 8% !important;
}

.table_manage_user table thead tr th:nth-child(10),
.table_manage_user table tbody tr td:nth-child(10) {
  width: 15% !important;
}

.table_manage_user table thead tr th:nth-child(11),
.table_manage_user table tbody tr td:nth-child(11) {
  width: 2% !important;
}

.table_manage_user table tbody tr td {
  padding: 0.75rem 0.75rem !important;
}

.select_check_user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 2rem;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--e-global-before-login-background);
}


.select_check_user_right {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  gap: 1rem !important;
  width:fit-content !important;
  font: normal normal 600 14px/18px Open Sans !important;
  }
  
.select_check_user_left {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  }

  .table_manage_user .status {
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    margin-right: 0.4rem;
    display: inline-block;
  }
  
  .status.inactive {
    background: #cb4d42;
  }
  
  .status.invited {
    background: #e6a901;
  }
  
  .status.active {
    background: #38c378;
  }
  .status-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .status-icon.all {
    background-color: #707070;
  }
  
  .status-icon.active {
    background-color: #38c378;
  }
  
  .status-icon.inactive {
    background-color: #cb4d42;
  }
  
  .status-icon.invited {
    background-color: #e6a901;
  }