.details-key {
  font: normal normal 600 0.90rem/1.060rem Open Sans;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin-top: 0.313rem;
  text-transform: none;
  white-space: nowrap;
}
.details-value {
  text-align: left;
  font: normal normal 600 0.90rem/1.060rem Open Sans;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 0.313rem;
  opacity: 1;
  text-transform: none;

}
.timeline-details-key {
  font: normal normal 600 1.075rem/1.063rem Open Sans;
  color: #707070;
  opacity: 1;
  margin-top: 0.313rem;
  margin-bottom: 0.5rem;
  text-transform: none;
}
.details-dropdown {
  max-height: 51.25rem;
  /* overflow-y: scroll; */
}
