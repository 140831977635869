/* Custom Tooltip Styling */
.tooltip-inner {
  background: #ffffff !important; 
  box-shadow: 0px 0px 45px rgba(163, 163, 163, 0.15) !important;
  border-radius: 15px !important; 
  padding: 10px 15px !important; 
  font-size: 14px !important; 
  color: #000000 !important;
  font-weight: bold !important; 
  text-align: center !important;
  opacity: 0.9 !important; 
}

/* Tooltip arrow customization */
.tooltip .tooltip-arrow::before {
  border-color: #ffffff !important; 
  border-width: 6px !important;
}
