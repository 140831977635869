.container {
  /* width: 306px; */
  /* 
  width: calc(100% - 1050px); */
  height: 357px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a326;
  border-radius: 15px;
}

.title {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
}

.mtd {
  font: normal normal normal 14px/19px Open Sans;
  color: #000000;
}

.legend {
  font: normal normal normal 12px/17px Open Sans;
  color: #000000;
}
