.hourlyCard {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  border: none;
  opacity: 1;
  margin-bottom: 30px;
}

.hourlyCardHeader {
  border-bottom: 1px solid #f2f2f2;
  margin: 0 26px;
  padding: 27px 0 16px 0;
}

/* Styles.module.css or styles.css */
.leftSection {
  height: 240px;
}

.equipmentInfo {
  padding-bottom: 50px;
  font: normal normal 600 14px/19px "Open Sans", sans-serif;
  color: #000000;
  opacity: 1;
}

.operatorInfo {
  font: normal normal normal 12px/17px "Open Sans", sans-serif;
  color: #6f7070;
  opacity: 1;
}

.details {
  margin-top: 10px;
}

.detailItem {
  font: normal normal normal 12px/22px "Open Sans", sans-serif;
  color: #000000;
  margin-bottom: 5px;
}

.detailValue {
  font: normal normal 600 12px/22px "Open Sans", sans-serif;
  color: #000000;
}

.chartContainer {
  width: 85%;
}
