body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify .Toastify__toast-container {
  z-index: 99999999;
}

.popup-field {
  font: normal normal normal 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #707070;
}

.popup-field-value {
  font: normal normal 600 0.9rem/1rem Open Sans;
  margin-top: 0.625rem;
  color: #000000;
}

.flyout-card {
  width: 0.7rem;
  height: 1.3rem;
  opacity: 1;
  margin-left: 0.93rem;
  margin-top: 0.76rem;
  margin-right: 0.34375rem;
}

.flyout {
  width: 13.125rem;
  height: 17.5rem;
  background: #ffffff;
  background-position: 0% 0%;
  background-repeat: no-repeat;
  box-shadow: 0rem 0.1875rem 0.375rem rgba(0, 0, 0, 0.29);
  opacity: 1;
  margin-left: 3rem;
  margin-top: 3rem;
  position: absolute;
  border-radius: 1rem;
  z-index: 1;
}

.mm-body {
  /* font-size: 1.4rem; */
  line-height: 2.5rem;
  font-weight: 400;
  min-width: 31.313rem;
  overflow-x: hidden;
  color: var(--e-global-color-dark-blue);
  scroll-behavior: smooth;
  font-size: 75%;
  height: 100%;
}

.mm-top_heading h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: var(--e-global-before-rightbar-text-color);
}

.modal-container-heading {
  border-radius: 0.625rem;
  min-width: 40rem;
  min-height: 49.375rem;
  border: 0.1rem solid black;
  margin-top: 1.25rem;
  margin-bottom: 8.25rem;
  background-color: white;
}

.modal-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* border-radius: 0.625rem; */
  max-height: 60rem !important;
  margin-top: 5rem;
  padding: 0 0rem 1.5rem 0rem;
  background-color: white;
  position: fixed;
}

.custom-table {
  width: 38rem;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  text-align: left;
  border: none;
  padding: 0.5rem;
}

.custom-table table {
  width: 100%;
}

.custom-table thead tr {
  font: normal normal 600 1.075rem/1.0625rem Open Sans;
  margin-bottom: 0.563rem;
  height: 3rem;
}

.custom-table tbody tr {
  height: 3rem;
  font: normal normal normal 1.075rem/1.0625rem Open Sans;
}

.custom-table th,
.custom-table td {
  text-align: left;
  border: none;
  padding: 0.5rem;
}

.custom-table thead th:nth-child(1),
.custom-table tbody tr td:nth-child(1) {
  width: 0.625rem;
  /* Adjust the first column width as needed */
}

.custom-table thead th:nth-child(2),
.custom-table tbody tr td:nth-child(2) {
  width: 25%;
  /* Adjust the second column width as needed */
}

.custom-table thead th:nth-child(3),
.custom-table tbody tr td:nth-child(3) {
  width: 25%;
  /* Adjust the third column width as needed */
}

.custom-table thead th:nth-child(4),
.custom-table tbody tr td:nth-child(4) {
  width: 20%;
  /* Adjust the fourth column width as needed */
}

.custom-table thead th:nth-child(5),
.custom-table tbody tr td:nth-child(5) {
  width: 20%;
  /* Adjust the fifth column width as needed */
}

.custom-table thead th:nth-child(6),
.custom-table tbody tr td:nth-child(6) {
  width: 10%;
}

.live-active {
  background-color: #E2ECFC;
  color: #000 !important;
}

.live-inactive {
  background-color: white;
  color: rgb(0, 0, 0);
}

.assets-button {
  font: normal normal normal 1.075rem/1.125rem Open Sans;
  color: #707070;
}

.scroll-div::-webkit-scrollbar {
  display: none;
}

.category-filter-dropdown {
  font: normal normal normal 1.075rem/0.875rem "Open Sans";
  margin-top: 0.4rem;
  margin-left: 0.5rem;
  color: #2b2d2f;
  white-space: nowrap;
}

.live-trip-table-footer {
  margin-top: -14px;
  padding: 1.5rem;
  position: relative;
  height: 3.25rem;
  bottom: -16px;
  right: 0;
  box-shadow: 0.063rem -0.063rem 0.375rem #00000029;
  cursor: pointer;
}