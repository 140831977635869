/* Table.module.css */

.customTable {
  width: 100%;
  border-collapse: collapse;
}

.customTable td {
  font: normal normal normal 14px/19px Open Sans;
  color: #1a1a1a;
  border-bottom: 1px solid #eaeaea;
}

.tableContainer {
  min-height:550px;
  flex-grow: 1;
  overflow-y: auto;
  
  /* Adds vertical scroll if content exceeds height */
}

.itemHeader td {
  font: italic normal bold 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #1a1a1a;
  padding: 12px;
}

.customRow td {
  padding: 12px;
  text-align: left;
}

.tableHeader {
  font: normal normal 600 12px/17px Open Sans;
  color: #576b8d;
}

.groupBadge {
  text-align: center;
  font: normal normal bold 12px/17px Open Sans;
  color: #1A1A1A;
  background: #EFF3FA;
  border-radius: 15px;
  width: 104px;
  height: 30px;
}

.borderNone {
  border: none !important;
}

.groupBadgeRow {
  display: flex;
  align-items: center;
}

.tableFooter {
  border-top: 1px solid #e1e8f4;
  margin-top: auto;
  font-size: 13px;
  color: #6a7c9a;
  padding: 20px 18px;
  padding-top: 10px;
}