.dateTimeSelect input {
    color: #6b7b8e !important;
  }
  .addShiftReportBtn {
    background: #186fd9;
    border-radius: 15px;
    font: normal normal bold 12px/17px Open Sans;
    color: #ffffff;
    cursor: pointer;
  }
  .filterBtn {
    border: 1px solid #e1e8f4;
    border-radius: 2rem;
    color: #1a1a1a;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 8px;
    gap: 2px;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: #8190ad;
    background-color: #e1e4ea;
  }
  
  
  