.notificationTitle{
    font: normal normal bold 14px/19px Open Sans;
color: hsla(0, 0%, 10%, 1);
}

.notification{
    font: normal normal normal 14px/20px Open Sans;
    color: hsla(0, 0%, 10%, 1);
}

.notificationTime{
    font: normal normal 600 12px/16px Open Sans;
color: hsla(218, 24%, 45%, 1);
}

.notificationItem {
    transition: background 0.3s ease; /* Smooth transition effect */
  }
  
  .notificationItem:hover {
    background: #F8FAFD;
  }

  .boxContainer {
    height: 460px;
    max-height: 460px;
    backdrop-filter: blur(10px); /* Proper syntax */
    overflow-y: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
  

  