.container {
  width: 200px;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 45px #a3a3a32b;
  border-radius: 15px;
  position: absolute;
  z-index: 2;
  top: 100%;
  margin-top: 6px;
  padding: 18px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.container p {
    color: #1A1A1A;
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 600;
}