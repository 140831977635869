.country-code-container {
    width: 100px;
    border-radius: 4px;
  }
  
.country-code-select {
    min-width: 120px;
    height:40px;
  }
.country-code-select .DynamicSelectField_dropdownContainer__2B1EK{
    height:40px;
  }
  
  .country-code-select :global(.optionContainer) {
    display: flex;
    align-items: center;
  }
  
  .country-code-select :global(.optionLabel) {
    margin-left: 8px;
  }