.react-datepicker-wrapper {
    width: 100%;
  }
  
  .custom-date-time-picker-normal {
    width: 100%;
    padding: 0 1rem;
    height: 3.25rem;
    border-radius: 0.4rem;
    font-weight: 600;
    /* font-size: 1.3rem; */
    color: "#1A1A1A";
    border: solid 0.1rem #e1e8f4 !important;
  }
  
  .custom-date-time-picker-error {
    width: 100%;
    padding: 0 1rem;
    height: 3.25rem;
    font-weight: 600;
    /* font-size: 1.3rem; */
    color: "#1A1A1A";
    border-radius: 0.4rem;
    border: solid 0.1rem #ba3938;
  }
  