.mainContainer {
    width: 700px;
    max-height: 712px;
    background: #FFFFFF;
    box-shadow: 0px 6px 20px #00000029;
    border: 1px solid #DDE5F3;
    border-radius: 10px;
    opacity: 1;
    position: relative;
    right: 625px;
    top: 10px;
    
   
}

.title {
    font: normal normal bold 22px/30px Open Sans;
    color: #092344;
}

.filterBtn {
    cursor: pointer;
    border: 1px solid rgb(221, 229, 243);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.crossIcon{
    position: absolute;
    top: -14px; 
    right: -5px; 
    cursor: pointer;
}