.tableContainer {
  overflow-y: auto;
  height: calc(100% - 47.5px);
}

.wrapper {
  height: calc(100% - 8rem);
  border: 1px solid #e1e8f4;
  border-radius: 10px;
  opacity: 1;
  box-shadow: 0px 2px 6px #0000000f;
  position: relative;
}

.mainTable {
  width: 100%;
  border-collapse: collapse;
}

/* .sticky {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
} */
.assetIdHeader,
.monthHeader,
.actionsHeader {
  padding: 15px 0px;
  text-align: center;
  background-color: #f8fafd;
  font: normal normal bold 12px/18px Open Sans;
  letter-spacing: 0px;
  color: #1a1a1a;
}
.monthHeader {
  width: 6.66%;
}
.emptySection {
  /* border-bottom: 1px solid #e1e8f4; */
  background-color: #f8fafd;
}
.actionsHeader {
  width: 7.5%;
  border-top-right-radius: 10px;
  border-left: 1px solid #e1e8f4;
}
.assetIdHeader {
  text-align: left;
  padding: 0 20px;
  width: 12.5%;
  border-top-left-radius: 10px;
  border-right: 1px solid #e1e8f4;
}

.bodyRow {
  cursor: pointer;
  border-top: 1px solid #e1e8f4;
  border-bottom: 1px solid #e1e8f4;
}

.assetId {
  padding: 10px 20px;
  text-align: left;
  font: normal normal 600 12px/18px Open Sans;
  color: #1a1a1a;
  background-color: #f8fafd;
  border-right: 1px solid #e1e8f4;
}

.targetValue {
  text-align: center;
  font: normal normal 600 12px/18px Open Sans;
  letter-spacing: 0px;
  color: #1a1a1a;
}

.actionsCell {
  border-left: 1px solid #e1e8f4;
}
