.card-performance {
  width: 50%;
  background-color: #ffffff;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  border: none;
  opacity: 1;
}

.performance-card-header {
  border-bottom: 1px solid #ebebeb;
}

.performance-section {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  font: normal normal 600 12px/24px Open Sans;
}

.card-bcm-breakdown {
  width: 100%;
  border: none;
  background-color: #fafafa;
  border-radius: 15px;
  opacity: 1;
}

.bcm-breakdown-label {
  font: normal normal normal 11px/22px Open Sans;
}

.bcm-breakdown-value {
  text-align: right;
  font: normal normal 600 12px/17px Open Sans;
}

.performance-total-label {
  font: normal normal normal 11px/15px Open Sans;
}

.bcm-breakdown-header {
  font: normal normal 600 12px/17px Open Sans;
}

.performance-icon {
  width: 23px;
  height: 23px;
  /* UI Properties */
  background-color: #4f74d9;
  transform: matrix(0.98, 0.21, -0.21, 0.98, 0, 0);
  /* UI Properties */
  border: 1px solid #ffffff;
  display: "inline-block";
}
