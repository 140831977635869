@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap");

html {
  font-size: 12px;
}

#report-outer-view {
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

#report-outer-view::-webkit-scrollbar {
  width: 0px;
}

.report-view {
  background-color: #fff;
  margin: 20px auto;
  font-size: 12px;
  height: 842px;
  width: 765px;
}

.report-view-pdf {
  background-color: #fff;
  margin: 20px auto;
  font-size: 12px;
  height: 1000px;
  width: 765px;
}

.input-sign {
  border: 1px solid #ebebeb;
}

/* CSS for DynamicTable component */
.table-item {
  padding: 10px;
  word-wrap: break-word;
  /* Word will be wrapped to fit into the container */
  white-space: normal;
  /* Allow wrapping long lines */
  overflow: hidden;
  /* Hide overflowing content */
}

.report-section-2 {
  background-color: #fafafa;
  font-size: 12px;
}

.border-y {
  border-top: 0.08rem solid #ebebeb;
  border-bottom: 0.08rem solid #ebebeb;
}

.report-view::-webkit-scrollbar {
  background-color: transparent;
  width: 0px;
}

.report-view::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.report-view-pdf::-webkit-scrollbar {
  background-color: transparent;
  width: 15px;
}

.report-view-pdf::-webkit-scrollbar-thumb {
  border-radius: 20px;
  border: 4px solid transparent;
  background-color: rgba(0, 0, 0, 0.2);
  background-clip: content-box;
}

.border-y-solid {
  border-top: 0.08rem solid #707070;
  border-bottom: 0.08rem solid #707070;
}

.border-r {
  border-right: 0.08rem solid #ebebeb;
}

.border-el {
  border-top: 0.08rem solid #707070;
  border-bottom: 0.08rem solid #707070;
  border-right: 0.08rem solid #707070;
}

.padx-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.pady-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pady-1 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.color-success {
  color: #2a7b39;
}

.color-faulty {
  color: #aa7827;
}