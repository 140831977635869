.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    line-height: 17.5px;
    left: 0%;
    width: 100%;
    padding: 0px 10px;
    border: 1px solid #DCE4F0;
    border-radius: 4px;
    background-color: #E1E8F4;
    font-size: 11px;
    font-weight: 600;
    color: #1A1A1A;
    box-shadow: 1px 3px 4px #00000029;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}
