.action-button{
    border: 1px solid #E1E8F4;
    border-radius: 4px;
    width: 8.5rem;
    color: #000000;
    font: normal normal bold 12px/17px Open Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #ffffff;
}