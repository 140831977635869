.weatherCard {
  width: 315px;
  height: 158px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 15px;
  padding-left: 0;
  cursor: grab;
  z-index: 1049;
  transition: box-shadow 0.3s;
  background: #ffffff;
  box-shadow: 0px 0px 45px #90909026;
  border-radius: 15px;
  opacity: 0.9;
}

.weatherCard:active {
  cursor: grabbing;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.weatherClose {
  font-size: x-large;
  cursor: pointer;
  float: right;
  transform: matrix(0, 1, -1, 0, 0, 0);
  opacity: 1;
  color: #afafaf;
}

/* .weatherInfo {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    color: #333;
} */

.weatherTemp {
  font: normal normal normal 24px/24px "Inter", sans-serif;
  color: #000000;
}

.weatherDescription {
  font: normal normal normal 11px/24px Open Sans;
  color: #000000;
}

.weatherIcon img {
  width: 120px;
  height: 90px;
  background: transparent linear-gradient(125deg, #ffffffb3 42%, #ffffff00 100%);
}

.weatherLabel {
  padding-right: 3rem !important;
  padding-bottom: 0.5rem !important;
  color: #707070;
  font: normal normal normal 10px/14px Open Sans;
  opacity: 1;
}

.weatherVal {
  font: normal normal normal 10px/14px Open Sans;
  color: #383838;
  opacity: 1;
}
