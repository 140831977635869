.container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(7, 22, 41, 0.85);
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal {
  background-color: #fffafa;
  padding: 32px;
  border-radius: 10px;
  width: 700px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  border: 1px solid #c13e3d;
}
.mid {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: #c13e3d;
}
.message {
  font-size: 14px;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  border-radius: 20px;
  background-color: #14233c;
  padding: 5px 20px;
  font-size: 14px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
