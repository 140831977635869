.card-ex-summary {
  background-color: #ffffff;
  box-shadow: 0px 0px 45px #a3a3a31f;
  border-radius: 15px;
  border: none;
}

.border-bottom {
  border-bottom: 1px solid #f2f2f2;
}

.ex-header-label {
  font: normal normal 600 14px/19px Open Sans;
  color: #000000;
  text-transform: capitalize;
}

.ex-label {
  text-decoration: underline;
  font: normal normal 600 11px/15px Open Sans;
  color: #000000;
  cursor: pointer;
}

.card-standby {
  width: 100%;
  border: none;
  background-color: #fafafa;
  border-radius: 15px;
  opacity: 1;
}

.standby-label {
  font: normal normal 600 11px/15px Open Sans;
  color: #000000;
}

.standby-val {
  font: normal normal 600 12px/17px Open Sans;
  color: #000000;
}




.tooltip-parent {
  position: relative;
}




