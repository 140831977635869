.alertContainer {
  position: fixed;
  right: 0;
  font-family: "Arial", sans-serif;
  color: #333;

  overflow-y: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch;
  z-index: 9999;
  background: transparent;
}

.alertTitle {
  font: normal normal bold 18px/20px "Open Sans";
  color: #1a1a1a;
}

.showLessBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
  background: #fafbfd;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  font: normal normal bold 12px/17px "Open Sans";
  color: #1a1a1a;
  cursor: pointer;
}

.clearAllBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 78px;
  height: 30px;
  background: #fafbfd;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  font: normal normal bold 12px/17px "Open Sans";
  color: #1a1a1a;
  cursor: pointer;
}

.shadow {
  position: relative;
  background: rgba(250, 251, 253, 1);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid rgba(225, 232, 244, 1);
  border-radius: 10px;
  opacity: 1;
}
