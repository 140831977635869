.trips-custom-table,
.trips-report-table {
  margin-top: 1rem;
  width: 100%;
  border-collapse: collapse;
  font-size: 1.075rem;
}

.trips-custom-table th,
.trips-custom-table td,
.trips-report-table th,
.trips-report-table td {
  text-align: left;
}

.trips-custom-table thead tr {
  font: normal normal 600 1.075rem/1.063rem Open Sans;
  height: 2.875rem;
  margin-bottom: 0.563rem;
  border-bottom: 0.08rem solid #383838;
  border-top: 0.08rem solid #383838;
}

.trips-report-table thead tr {
  font: normal normal 600 1.075rem/1.063rem Open Sans;
  height: 2.875rem;
  margin-bottom: 0.563rem;
  border-bottom: 1px solid #707070;
}

.trips-custom-table tbody tr,
.trips-report-table tbody tr {
  height: 2.563rem;
  /* border-bottom: 0.1rem solid #ebebeb; */
  border-top: 0.1rem solid #ebebeb;
}


.trips-custom-table th:nth-child(1),
.trips-custom-table td:nth-child(1) {
  width: 0.5%;
}


.trips-custom-table th:nth-child(2),
.trips-custom-table td:nth-child(2) {
  width: 6%;
}

.trips-custom-table th:nth-child(3),
.trips-custom-table td:nth-child(3) {
  width: 8%;
}

.trips-custom-table th:nth-child(4),
.trips-custom-table td:nth-child(4) {
  width: 12%;
}

.trips-custom-table th:nth-child(5),
.trips-custom-table td:nth-child(5) {
  width: 10%;
}

.trips-custom-table th:nth-child(6),
.trips-custom-table td:nth-child(6) {
  width: 18%;
}

.trips-custom-table th:nth-child(7),
.trips-custom-table td:nth-child(7) {
  width: 6%;
}

.trips-custom-table th:nth-child(8),
.trips-custom-table td:nth-child(8) {
  width: 10%;
}

.trips-custom-table th:nth-child(9),
.trips-custom-table td:nth-child(9) {
  width: 8%;
}

.trips-custom-table th:nth-child(10),
.trips-custom-table td:nth-child(10) {
  width: 8%;
}

.trips-custom-table thead .header-row {
  background-color: #f0f0f0;
}

.trips-custom-table tbody td span {
  color: #707070;
}

.trips-custom-table tbody tr:hover {
  background-color: #f7f7f7;
}

.row-details {
  border-radius: 0.825rem;
  min-width: 45.875rem;

  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow-y: hidden;
}

.full-screen-modal {
  border-radius: 0;
  width: 100vw;
  height: 100vh;
  border: 1px solid black;
  background-color: white;
  position: absolute;
  top: 0 !important;
  z-index: 9999;
}

.modal-heading {
  width: 100%;
  background: #ffffff;
  background-repeat: no-repeat;
  border: 1px solid #ebebeb;
  border-radius: 10px 0px 0px 0px;
  opacity: 1;
}

.modal-map {
  height: calc(100% - 3.6rem);
}

.selected-row {
  margin-top: 1.75rem;
  margin-left: 1.625rem;
  width: 734px;
  height: 71px;
  background: #ffffff 0% 0% no-repeat padding-box;
  font: normal normal 600 1.075rem/1.1875rem Open Sans;
}

.row-highlight {
  background: #266e4c1a 0% 0% no-repeat padding-box;
}

.table-footer {
  width: 100%;
  height: 3.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #ffffff;
  font: normal normal 600 1.075rem/1.063rem Open Sans;

  letter-spacing: 0px;
  color: #6e6e6e;
  opacity: 1;
  border-top: 0.1rem solid #ebebeb;
  border-bottom: 0.1rem solid #ebebeb;
  padding-left: 2rem;
}

.scroll-div::-webkit-scrollbar {
  width: 0rem;
  display: none !important;
}

.asm-timeline-graph-modal {
  border-radius: 0;
  width: 100vw;
  height: calc(100vh - 39%);
  border: 1px solid black;
  background-color: white;
  position: absolute;
  top: 0 !important;
  z-index: 9999;
}