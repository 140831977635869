
.downloadModal {
    height: 180px;
    width: 450px;
    background: #ffffff;
    box-shadow: 0px 0px 45px #a3a3a329;
    border-radius: 15px;
    top: 150px;
  }
  .title {
    font: normal normal 600 14px/14px Open Sans;
    color: "#000000";
  }
  .header {
    padding: 30px 0 17px 0;
    border :none !important
  }

  .closeIcon {
    border-radius: 100%;
    cursor: pointer;
    display: flex;
   align-items: center;
    justify-content: center;
    padding: 1px,
  }

  .downloadBtn {
    width: 101px;
    height: 30px;
    /* UI Properties */
    background: #3880f5 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 35px #4f74d940;
    border-radius: 20px;
    font: normal normal 600 11px/15px Open Sans;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 1;
    cursor: pointer;
  }